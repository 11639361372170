<template>
  <fw-panel featured :title="'Configurações'" class="mb-5">
    <LoadingPlaceholder v-if="loading" />

    <div v-else-if="!loading && space && space.key">
      <PanelSpaceData
        :space="space"
        :title="'Espaço'"
        :saving-data="savingSpaceData"
        :can-edit="validations.can_edit_space"
        @save="$emit('save-space', $event)"
      ></PanelSpaceData>

      <fw-panel :title="'Filas'" class="my-5" :loading="savingQueuesData" after-loading-checked>
        <fw-panel-info v-if="!space.queues || !space.queues.length" type="basic" :centered="true">
          {{ $t('noQueues') }}
        </fw-panel-info>

        <ExpandList
          :add-title="$t('createQueue')"
          :empty="!space.queues || !space.queues.length"
          :show-add-items="canAddQueues"
          @add-element="addQueue"
        >
          <template #list>
            <div v-for="queue in space.queues" :key="queue.key">
              <div class="w-full flex gap-4 rounded-xl items-center p-2 border-b border-gray-100">
                <div class="flex-1">
                  <div class="flex gap-2 items-center">
                    <div class="font-bold mx-2 w-32 flex-shrink-0 text-gray-500">{{ queue.prefix | uppercase }}</div>

                    <div class="flex-1">
                      <fw-heading size="h4">{{ queue.title }}</fw-heading>
                    </div>
                  </div>
                </div>

                <b-dropdown
                  v-if="
                    (validations.can_edit_space && validations.queues[queue.key].can_edit_queue) |
                      (validations.can_edit_space && validations.queues[queue.key].can_delete_queue)
                  "
                  aria-role="list"
                  position="is-bottom-left"
                >
                  <fw-button-dropdown slot="trigger" :chevron="false" type="simple" class="flex flex-col" label="Ações">
                    <fw-icon-more class="w-5 h-5" />
                  </fw-button-dropdown>
                  <b-dropdown-item
                    v-if="validations.can_edit_space && validations.queues[queue.key].can_edit_queue"
                    custom
                    aria-role="menuitem"
                    class="paddingless"
                  >
                    <fw-button
                      type="basic-action"
                      size="sm"
                      label="Editar"
                      custom-class="w-full"
                      @click.native="editQueue(queue)"
                    >
                      {{ $t('edit') }}
                    </fw-button>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="validations.can_edit_space && validations.queues[queue.key].can_delete_queue"
                    custom
                    aria-role="menuitem"
                    class="paddingless"
                  >
                    <fw-button
                      type="basic-action"
                      size="sm"
                      custom-class="w-full"
                      label="Eliminar"
                      @click.native="deleteQueue(queue.key)"
                    >
                      {{ $t('delete') }}
                    </fw-button>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </template>
        </ExpandList>
      </fw-panel>

      <fw-panel :title="'Assuntos'" class="my-5" :loading="savingSubjectsData" after-loading-checked>
        <fw-panel-info v-if="!types || !types.length" centered empty class="p-3">
          <p>{{ $t('noSubjects') }}</p>
        </fw-panel-info>

        <fw-panel-info v-if="!space.queues || !space.queues.length" type="orange" icon boxed clean class="p-3">
          <p>{{ $t('addSomeQueuesFirst') }}</p>
        </fw-panel-info>

        <ExpandList
          :add-title="$t('addType')"
          :empty="!types || !types.length"
          :show-add-items="canAddTypes"
          class="mt-2"
          @add-element="addType"
        >
          <template #list>
            <div v-for="type in types" :key="type.key">
              <div class="w-full flex gap-4 rounded-xl items-center p-3 border-b border-gray-100">
                <div class="flex-1 flex items-center">
                  <div class="flex-1">
                    <fw-heading size="h4">{{ type.title }}</fw-heading>
                    <small>{{ type.show_on.join(', ') | uppercase }}</small>
                  </div>
                  <fw-tag type="light-border-box">{{ type.queue.prefix | uppercase }}</fw-tag>
                </div>

                <b-dropdown
                  v-if="
                    validations.can_edit_space &&
                      (validations.types[type.key].can_delete_type || validations.types[type.key].can_edit_type)
                  "
                  aria-role="list"
                  position="is-bottom-left"
                >
                  <fw-button-dropdown slot="trigger" :chevron="false" type="simple" class="flex flex-col" label="Ações">
                    <fw-icon-more class="w-5 h-5" />
                  </fw-button-dropdown>
                  <b-dropdown-item
                    v-if="validations.can_edit_space && validations.types[type.key].can_edit_type"
                    custom
                    aria-role="menuitem"
                    class="paddingless"
                  >
                    <fw-button
                      type="basic-action"
                      size="sm"
                      label="Editar"
                      custom-class="w-full"
                      @click.native="editType(type)"
                    >
                      {{ $t('edit') }}
                    </fw-button>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="validations.types[type.key].can_delete_type"
                    custom
                    aria-role="menuitem"
                    class="paddingless"
                  >
                    <fw-button
                      type="basic-action"
                      size="sm"
                      custom-class="w-full"
                      label="Eliminar"
                      @click.native="deleteType(type)"
                    >
                      {{ $t('delete') }}
                    </fw-button>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </template>
        </ExpandList>
      </fw-panel>

      <fw-panel :title="'Schemas'" class="my-5" :loading="savingSchemasData" after-loading-checked>
        <fw-panel-info v-if="!types || !types.length" centered empty class="p-3">
          <p>{{ $t('noSchemas') }}</p>
        </fw-panel-info>

        <ExpandList
          :add-title="$t('addType')"
          :empty="!schemas || !schemas.length"
          :show-add-items="canAddTypes"
          class="mt-2"
          @add-element="addSchema"
        >
          <template #list>
            <div v-for="schema in schemas" :key="schema.key">
              <div class="w-full flex gap-4 rounded-xl items-center p-3 border-b border-gray-100">
                <div class="flex-1 flex items-center">
                  <div class="flex-1">
                    <fw-heading size="h4">{{ type.title }}</fw-heading>
                  </div>
                  <!-- <fw-tag type="light-border-box">{{ type.queue.prefix | uppercase }}</fw-tag> -->
                </div>

                <b-dropdown v-if="validations.can_edit_space" aria-role="list" position="is-bottom-left">
                  <fw-button-dropdown slot="trigger" :chevron="false" type="simple" class="flex flex-col" label="Ações">
                    <fw-icon-more class="w-5 h-5" />
                  </fw-button-dropdown>
                  <b-dropdown-item v-if="validations.can_edit_space" custom aria-role="menuitem" class="paddingless">
                    <fw-button
                      type="basic-action"
                      size="sm"
                      label="Editar"
                      custom-class="w-full"
                      @click.native="editSchema(schema)"
                    >
                      {{ $t('edit') }}
                    </fw-button>
                  </b-dropdown-item>
                  <b-dropdown-item custom aria-role="menuitem" class="paddingless">
                    <fw-button
                      type="basic-action"
                      size="sm"
                      custom-class="w-full"
                      label="Eliminar"
                      @click.native="deleteSchema(schema)"
                    >
                      {{ $t('delete') }}
                    </fw-button>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </template>
        </ExpandList>
      </fw-panel>
    </div>

    <fw-panel-info debug label="Settings (raw)">
      <json-viewer :value="{ types, space, validations, schemas }"></json-viewer>
    </fw-panel-info>

    <fw-modal :active.sync="isModalActive" size="auto" boxed="sm" @close="closeModal">
      <PanelTypeData
        v-if="editingType"
        :type="editingType"
        :editing="true"
        :queues="space.queues"
        @close="closeModal"
        @save="saveTypeData"
      ></PanelTypeData>

      <PanelQueueData
        v-else-if="editingQueue"
        :queue="editingQueue"
        :editing="true"
        @close="closeModal"
        @save="saveQueueData"
      ></PanelQueueData>

      <PanelSchemaData
        v-else-if="editingSchema"
        :item="editingSchema"
        :editing="true"
        @close="closeModal"
        @save="saveSchemaData"
      ></PanelSchemaData>
    </fw-modal>
  </fw-panel>
</template>

<script>
import PanelSchemaData from '@/components/panels/PanelSchemaData'
import PanelSpaceData from '@/components/panels/PanelSpaceData'
import PanelQueueData from '@/components/panels/PanelQueueData'
import PanelTypeData from '@/components/panels/PanelTypeData'
import ExpandList from '@/fw-modules/fw-core-vue/ui/components/lists/ExpandList'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'

import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    PanelSchemaData,
    PanelSpaceData,
    PanelQueueData,
    PanelTypeData,
    ExpandList,
    LoadingPlaceholder,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    savingSpaceData: {
      type: Boolean,
      default: false,
    },
    savingQueuesData: {
      type: Boolean,
      default: false,
    },
    validations: {
      type: Object,
      default: () => {},
    },
    space: {
      type: Object,
      default: () => {},
    },
    schemas: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isModalActive: false,
      savingSchemasData: false,
      savingSubjectsData: false,
      editingQueue: null,
      editingType: null,
      editingSchema: null,
      types: [],
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    canAddTypes() {
      return Boolean(this.validations.can_edit_space && this.space.queues && this.space.queues.length)
    },

    canAddQueues() {
      return this.validations.can_edit_space
    },
  },

  watch: {
    space() {
      this.setTypes()
    },
  },

  mounted() {
    this.setTypes()
  },

  methods: {
    setTypes() {
      this.types = []
      if (this.space.queues && this.space.queues.length) {
        this.space.queues.forEach(queue => {
          queue.types?.forEach(type => {
            this.types.push({ ...type, queue: queue })
          })
        })
      }
      if (this.types) this.types.sort((a, b) => a.title.localeCompare(b.title))
      if (this.space.queues) this.space.queues.sort((a, b) => a.title.localeCompare(b.title))
    },

    closeModal() {
      this.isModalActive = false
      this.editingType = null
      this.editingQueue = null
    },

    addQueue() {
      this.editingQueue = {
        title: '',
        prefix: '',
      }
      this.isModalActive = true
    },

    editQueue(queue) {
      this.editingQueue = queue
      this.isModalActive = true
    },

    editType(type) {
      this.editingType = type
      this.isModalActive = true
    },

    addType() {
      this.editingType = {
        title: '',
        queue_key: '',
        show_on: null,
        description: '',
      }
      this.isModalActive = true
    },

    editSchema(schema) {
      this.editingSchema = schema
      this.isModalActive = true
    },

    addSchema() {
      this.editingSchema = {
        key: null,
        title: '',
        queue_keys: [],
        schema: [
          {
            label: 'Escolha coisas',
            input_type: 'checkbox',
            options: ['123', '456', '789'],
          },
          {
            label: 'Escolha coisas 2',
            input_type: 'text',
          },
        ],
      }
      this.isModalActive = true
    },

    saveSchemaData(schemaData) {
      console.log('saveSchemaData :>> ', schemaData)
    },

    saveTypeData(typeData) {
      console.log('savetypeData :>> ', typeData)
      if (!typeData.key) return this.createType(typeData)
      else return this.updateType(typeData)
    },

    async createType(typeData) {
      this.savingSubjectsData = true
      console.log('createType :>> ', typeData)
      try {
        const res = await this.api.createSpaceTypes(this.space.key, typeData.queue.key, {
          title: typeData.title,
          description: typeData.description,
          // show_on: typeData.show_on.map(el => el.key),
          is_public: typeData.is_public,
        })
        this.editingType = null
        this.types.push({ ...res, queue: this.space.queues.find(el => el.key == res.queue_key) })
        this.validations.types[res.key] = {
          can_delete_type: res.can_delete_type,
          can_edit_type: res.can_edit_type,
        }
      } catch (error) {
        console.error('Error createSpaceTypes:', error)
        const errorKey = utils.errors(error).getKey()
        if (errorKey && errorKey == 'SpaceTypeAlreadyExists') {
          this.$buefy.dialog.alert({
            title: this.$t('spaceTypeAlreadyExists'),
            message: this.$t('spaceTypeAlreadyExistsMessage'),
            type: 'is-danger',
          })
        } else {
          this.$emit('handle-errors', error)
        }
      }
      this.savingSubjectsData = false
    },

    async updateType(typeData) {
      this.savingSubjectsData = true
      console.log('createType :>> ', typeData)
      try {
        const res = await this.api.updateSpaceTypes(this.space.key, typeData.key, {
          title: typeData.title,
          description: typeData.description,
          // show_on: typeData.show_on.map(el => el.key),
          is_public: typeData.is_public,
        })
        this.editingType = null
        const index = this.types.findIndex(el => el.key == res.key)
        if (index > -1) this.$set(this.types, index, { ...typeData, ...res })
        this.validations.types[res.key] = {
          can_delete_type: res.can_delete_type,
          can_edit_type: res.can_edit_type,
        }
      } catch (error) {
        console.error('Error updateSpaceTypes:', error)
        const errorKey = utils.errors(error).getKey()
        if (errorKey && errorKey == 'SpaceTypeAlreadyExists') {
          this.$buefy.dialog.alert({
            title: this.$t('spaceTypeAlreadyExists'),
            message: this.$t('spaceTypeAlreadyExistsMessage'),
            type: 'is-danger',
          })
        } else {
          this.$emit('handle-errors', error)
        }
      }
      this.savingSubjectsData = false
    },

    async deleteType(spaceType) {
      this.savingSubjectsData = true
      console.log('deleteType :>> ', spaceType)
      try {
        await this.api.deleteSpaceType(this.space.key, spaceType.key)
        this.editingType = null
        this.types = this.types.filter(el => el.key != spaceType.key)
      } catch (error) {
        console.error('Error deleteSpaceType:', error)
        this.$emit('handle-errors', error)
      }
      this.savingSubjectsData = false
    },

    createQueue(queueData) {
      console.log('createQueue :>> ', queueData)
      this.$emit('create-queue', queueData)
      this.editingQueue = null
    },

    deleteQueue(queueKey) {
      this.$emit('delete-queue', queueKey)
    },

    saveQueueData(queueData) {
      console.log('saveQueueData :>> ', queueData)
      if (!queueData.key) return this.createQueue(queueData)
      this.$emit('update-queue', queueData)
      this.editingQueue = null
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "noQueues": "Sem filas",
    "createQueue": "Criar fila",
    "noSubjects": "Sem assuntos",
    "addType": "Criar um assunto",
    "addSomeQueuesFirst": "Adicione uma fila para poder criar assuntos.",
    "spaceTypeAlreadyExists": "Assunto já definido",
    "spaceTypeAlreadyExistsMessage": "Já existe um assunto com o título que tentou definir. Por favor, indique outro para guardar as alterações.",
    "delete": "Apagar",
    "edit": "Editar"
  },
  "en": {
    "noQueues": "Sem filas",
    "createQueue": "Criar fila",
    "noSubjects": "No subjects",
    "addType": "Create a subject",
    "addSomeQueuesFirst": "Add a queue to create subjects",
    "spaceTypeAlreadyExists": "Subject already defined",
    "spaceTypeAlreadyExistsMessage": "There is already a subject with the title you tried to set. Please enter another one to save your changes.",
    "delete": "Delete",
    "edit": "Edit"
  }
}
</i18n>
