<template>
  <div>
    <fw-panel :title="type.key ? $t('editType') : $t('newType')" featured custom-class="mb-5 flex flex-col gap-5">
      <div>
        <fw-label>{{ $t('title.label') }}</fw-label>
        <TextInput
          v-model="type.title"
          :minlength="2"
          :maxlength="250"
          :placeholder="$t('title.placeholder')"
          :class="{
            error: $v.type.title.$error,
          }"
        >
        </TextInput>

        <fw-tip v-if="$v.type.title.$error" error>
          <span v-if="!$v.type.title.required">{{ $t('title.required') }}</span>
          <span v-else>{{ $t('title.invalid') }}</span>
        </fw-tip>
      </div>

      <div>
        <fw-label>{{ $t('queue.label') }}</fw-label>
        <multiselect
          v-model="type.queue"
          :options="queues"
          :show-labels="false"
          :clear-on-select="false"
          :allow-empty="false"
          :placeholder="$t('queue.placeholder')"
          :multiple="false"
          track-by="key"
          :custom-label="customLabel"
          :max-height="200"
        ></multiselect>

        <fw-tip v-if="$v.type.queue.$error" error>
          <span v-if="!$v.type.queue.required">{{ $t('queue.required') }}</span>
          <span v-else>{{ $t('queue.invalid') }}</span>
        </fw-tip>
      </div>

      <!-- <div>
        <fw-label>{{ $t('apps.label') }}</fw-label>
        <multiselect
          v-model="type.show_on"
          :options="apps"
          :show-labels="false"
          :clear-on-select="false"
          :allow-empty="false"
          :placeholder="$t('apps.placeholder')"
          :multiple="true"
          track-by="key"
          label="title"
          :max-height="200"
        ></multiselect>

        <fw-tip v-if="$v.type.queue.$error" error>
          <span v-if="!$v.type.queue.required">{{ $t('queue.required') }}</span>
          <span v-else>{{ $t('queue.invalid') }}</span>
        </fw-tip>
      </div> -->

      <div>
        <fw-label>{{ $t('description.label') }}</fw-label>
        <b-input v-model="type.description" type="textarea" :placeholder="$t('description.placeholder')" />

        <fw-tip v-if="$v.type.description.$error" error>
          <span v-if="!$v.type.description.required">{{ $t('description.required') }}</span>
          <span v-else>{{ $t('description.invalid') }}</span>
        </fw-tip>
      </div>

      <div>
        <fw-label>{{ $t('isPublic.label') }}</fw-label>
        <div>
          <b-switch v-model="type.is_public"> {{ $t('isPublic.placeholder') }}<br /> </b-switch>
          <fw-panel-info clean>{{ $t('isPublic.description') }}</fw-panel-info>
        </div>
      </div>
    </fw-panel>

    <fw-panel-info debug label="type data (raw)">
      <json-viewer :value="{ type, apps }"></json-viewer>
    </fw-panel-info>

    <div class="flex-1 flex justify-end items-center mt-3">
      <fw-button type="link-muted" class="mr-4" @click.native="$emit('close')">{{ $t('cancel') }}</fw-button>
      <fw-button type="primary" @click.native="saveType">{{ $t('save') }}</fw-button>
    </div>
  </div>
</template>

<script>
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import { FW_APPS } from '@/fw-modules/fw-core-vue/config'
import { required, maxLength, minLength } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    TextInput,
    Multiselect,
  },

  props: {
    type: {
      type: Object,
      default: () => {
        return {
          title: '',
          queue_key: '',
          show_on: null,
          is_public: false,
          description: '',
        }
      },
    },
    queues: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      apps: Object.freeze(FW_APPS),
    }
  },

  validations: {
    type: {
      title: { required, min: minLength(2), max: maxLength(250) },
      queue: { required },
      description: { required, min: minLength(2), max: maxLength(65000) },
      is_public: { required },
    },
  },

  methods: {
    customLabel({ prefix, title }) {
      return `${prefix.toUpperCase()} - ${title}`
    },

    saveType() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$emit('save', this.type)
      this.$emit('close')
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "charsLimit": "Limite de {limit} caracteres.",
    "thereAreErrors": "Existem erros de formulário",
    "newType": "Novo assunto",
    "editType": "Editar assunto",
    "save": "Guardar",
    "close": "Fechar",
    "edit": "Editar",
    "cancel": "Cancelar",
    "delete": "Apagar",
    "title": {
      "label": "Título",
      "placeholder": "Título",
      "required": "Insira um título",
      "invalid": "Insira um título válido"
    },
    "queue": {
      "label": "Fila",
      "placeholder": "Escolha uma fila",
      "required": "Escolha uma fila",
      "invalid": "Escolha uma fila válida"
    },
    "apps": {
      "label": "Applicações",
      "placeholder": "Escolha as applicações"
    },
    "description": {
      "label": "Descrição",
      "placeholder": "Descrição",
      "required": "Insira uma descrição",
      "invalid": "Insira uma descrição válida"
    },
    "isPublic": {
      "label": "Público",
      "placeholder": "Público",
      "description": "Indique se o assunto deve ser visível para todos os utilizadores."
    }
  },
  "en": {
    "charsLimit": "Limit of {limit} characters.",
    "thereAreErrors": "There are errors in the form",
    "newType": "New type",
    "editType": "Edit type",
    "save": "Save",
    "close": "Close",
    "edit": "Edit",
    "cancel": "Cancel",
    "delete": "Delete",
    "title": {
      "label": "Title",
      "placeholder": "Title",
      "required": "Enter a title",
      "invalid": "Enter a valid title"
    },
    "queue": {
      "label": "Queue",
      "placeholder": "Choose a queue",
      "required": "Choose a queue",
      "invalid": "Choose a valid queue"
    },
    "apps": {
      "label": "Applications",
      "placeholder": "Choose the applications"
    },
    "description": {
      "label": "Description",
      "placeholder": "Description",
      "required": "Enter a description",
      "invalid": "Enter a valid description"
    },
    "isPublic": {
      "label": "Public label",
      "placeholder": "Public",
      "description": "Public description"
    }
  }
}
</i18n>
