<template>
  <fw-panel featured :title="$t('moveTask', { taskId: taskId.toUpperCase() })">
    <div class="text-sm">{{ $t('chooseNewQueue') }}</div>
    <fw-panel :title="$t('queues')" :counter="queues ? queues.length : 0" boxed="xs" class="mt-3">
      <fw-panel-info v-if="!queues || !queues.length" type="basic" class="text-center my-5 text-gray-500">
        {{ $t('noQueues') }}
      </fw-panel-info>
      <RecycleScroller v-slot="{ item }" key-field="key" :items="queues" :item-size="50" :buffer="50">
        <div class="p-1 border-b border-gray-100" :class="{ 'cursor-not-allowed': item.key == queueKey }">
          <RecordQueue :queue="item" minimal :disabled="item.key == queueKey" @open="selectQueue">
            <template v-if="item.key == queueKey" #secondline>
              <fw-tag type="primary">{{ $t('currentQueue') }}</fw-tag>
            </template>
          </RecordQueue>
        </div>
      </RecycleScroller>
    </fw-panel>
  </fw-panel>
</template>

<script>
import RecordQueue from '@/components/records/RecordQueue'
import { RecycleScroller } from 'vue-virtual-scroller'

// import utils from '@/fw-modules/fw-core-vue/utilities/utils'
// import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  components: { RecordQueue, RecycleScroller },
  props: {
    taskId: {
      type: String,
      default: '',
    },
    queueKey: {
      type: String,
      default: '',
    },
    queues: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      newQueue: undefined,
    }
  },
  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },

    selectQueue(queue) {
      console.log('queueKey :>> ', queue)
      this.newQueue = queue

      this.$buefy.dialog.confirm({
        type: 'is-danger',
        title: this.$t('moveTask', { taskId: this.taskId }),
        message: this.$t('confirmMove', { taskId: this.taskId, queueName: queue.title }),
        confirmText: this.$t('button.move'),
        cancelText: this.$t('button.cancel'),
        onConfirm: async () => {
          this.saveSelection()
        },
      })
    },

    saveSelection() {
      this.$emit('save', this.newQueue)
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "moveTask": "Mover a task {taskId}",
    "chooseNewQueue": "Escolha a nova fila da tarefa:",
    "queues": "Filas",
    "noQueues": "Sem filas",
    "currentQueue": "Fila atual",
    "confirmMove": "Tem a certeza que quer mover a task <strong>{taskId}</strong> para a fila <strong>{queueName}</strong>?",
    "button": {
      "move": "Mover",
      "cancel": "Cancelar"
    }
  },
  "en": {
    "moveTask": "Move the task {taskId}",
    "chooseNewQueue": "Choose the new task queue:",
    "queues": "Queues",
    "noQueues": "Sem filas",
    "currentQueue": "Current queue",
    "confirmMove": "Are you sure you want to move <strong>{taskId}</strong> to the queue <strong>{queueName}</strong>?",
    "button": {
      "move": "Move",
      "cancel": "Cancel"
    }
  }
}
</i18n>
