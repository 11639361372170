<template>
  <fw-layout
    :full="isFullLayout"
    :loading="initialLoading"
    :notfound="notfound"
    :forbidden="forbidden"
    :loading-title="$t('loadingSpaceTitle')"
    back-to="emit"
    management
    @go-back-to="goBackTo"
  >
    <template #header-nav>
      <div class="flex flex-col max-w-xl">
        <v-clamp class="font-bold text-base" autoresize :max-lines="1">{{ space.title }}</v-clamp>
        <fw-label class="hidden md:flex" marginless>{{ space.prefix }}</fw-label>
      </div>
    </template>

    <template #header-toolbar>
      <BlockHeaderDebug>
        <fw-panel-info label="User permissions (raw)" debug>
          <json-viewer :value="{ userPermissions, validations }"></json-viewer>
        </fw-panel-info>
      </BlockHeaderDebug>
    </template>

    <template v-if="view != 'queue-task'" #main-sidebar>
      <SidebarSpace :current-queue="queueKey" :queues="sidebarQueues" :validations="validations" />
    </template>

    <template #main-content>
      <PanelSpaceDashboard
        v-if="view == 'dashboard'"
        :space="space"
        :users="users"
        :loading="loading"
        :queues-stats="queuesStats"
      />
      <PanelSpacePeople
        v-else-if="view == 'people'"
        :space="space"
        :users="users"
        :loading="loading"
        :users-roles="usersRoles"
        :can-edit-owner="validations.can_edit_space && validations.is_space_owner"
        :can-edit="validations.can_edit_space"
        :saving-data="savingData"
        @refresh-data="getCurrentViewData()"
        @update-managers="updateManagers"
        @delete-managers="deleteManagers"
      ></PanelSpacePeople>
      <PanelSpaceQueues v-else-if="view == 'queues'" />
      <PanelTasks v-else-if="view == 'queue-tasks'" />
      <PanelSpaceQueueTasks
        v-else-if="view == 'queue-task'"
        :space="space"
        :tasks="tasks"
        :users="users"
        :loading="loading"
        :view="view"
        :page="page"
        :pagination="{
          page: page,
          totalResults: totalResults,
          totalPages: totalPages,
          limit: limit,
        }"
        :starting-filter="appliedStatus"
        @page-changed="pageChanged"
        @task-updated="updateStatusOnList"
        @get-tasks="applyTasksFilter"
      ></PanelSpaceQueueTasks>
      <PanelSpaceSettings
        v-else-if="view == 'settings'"
        :space="space"
        :schemas="schemas"
        :saving-space-data="savingData"
        :saving-queues-data="savingQueuesData"
        :validations="validations"
        :loading="loading"
        @save-space="updateSpace"
        @create-queue="createQueue"
        @delete-queue="deleteQueue"
        @update-queue="updateQueue"
        @refresh-data="getCurrentViewData()"
        @handle-errors="handleErrors"
      ></PanelSpaceSettings>
      <PanelActivity
        v-else-if="view == 'activity' && validations.can_edit_space"
        :space="space"
        :active-filters="{
          space: spaceKey,
        }"
        :inject-payload="{ spaceKey: spaceKey }"
      ></PanelActivity>
      <PanelNotifications
        v-else-if="view == 'notifications' && validations.can_edit_space"
        :space="space"
        :active-filters="{
          space: spaceKey,
        }"
        :inject-payload="{ spaceKey: spaceKey }"
        :details-payload="{ spaceKey: spaceKey }"
      ></PanelNotifications>
    </template>

    <template #tapbar>
      <TapbarSpace />
    </template>
  </fw-layout>
</template>

<script>
import BlockHeaderDebug from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockHeaderDebug'
import PanelSpaceDashboard from '@/components/panels/PanelSpaceDashboard'
import PanelSpacePeople from '@/components/panels/PanelSpacePeople'
import PanelSpaceQueues from '@/components/panels/PanelSpaceQueues'
import PanelSpaceQueueTasks from '@/components/panels/PanelSpaceQueueTasks'
import PanelSpaceSettings from '@/components/panels/PanelSpaceSettings'
import PanelTasks from '@/components/panels/PanelTasks'
import PanelActivity from '@/fw-modules/fw-core-vue/ui/components/panels/PanelActivity'
import PanelNotifications from '@/fw-modules/fw-core-vue/ui/components/panels/PanelNotifications'
import SidebarSpace from '@/components/sidebars/SidebarSpace'
import TapbarSpace from '@/components/tapbars/TapbarSpace'

import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    PanelSpaceDashboard,
    PanelSpaceSettings,
    PanelSpaceQueues,
    PanelSpaceQueueTasks,
    PanelSpacePeople,
    PanelActivity,
    PanelNotifications,
    BlockHeaderDebug,
    SidebarSpace,
    TapbarSpace,
    PanelTasks,
  },

  data() {
    return {
      initialLoading: true,
      space: {},
      tasks: [],
      users: {},
      validations: {},
      usersRoles: {},
      sidebarQueues: [],
      loading: true,
      loadingQueues: true,
      appliedStatus: 'all',
      savingData: false,
      savingQueuesData: false,
      page: 1,
      totalResults: 0,
      totalPages: 1,
      limit: 25,
      forbidden: false,
      notfound: false,
      queuesStats: {},
      schemas: [],
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    spaceKey() {
      return this.$route.params.key
    },

    view() {
      return this.$route.meta.view ?? 'dashboard'
    },

    queueKey() {
      return this.$route.params.queueKey
    },

    userPermissions() {
      return this.$store.getters.getUserPermissions
    },

    isFullLayout() {
      return this.view && ['queue-task', 'queue-tasks'].includes(this.view)
    },
  },

  watch: {
    queueKey(newVal) {
      if (newVal) this.getQueueTasks()
    },

    view(newVal) {
      if (newVal) this.getCurrentViewData()
    },
  },

  mounted() {
    this.getCurrentViewData(true)
  },

  methods: {
    goBackTo() {
      if (this.view == 'queue-task') {
        if (this.appliedStatus) {
          this.$router.push({
            name: 'manage-space-queue',
            params: { key: this.spaceKey, queueKey: this.queueKey },
            query: { f: `status:${this.appliedStatus}` },
          })
        } else {
          this.$router.push({ name: 'manage-space-queue', params: { key: this.spaceKey, queueKey: this.queueKey } })
        }
      } else if (this.view == 'queue-tasks') {
        this.$router.push({ name: 'manage-space-queues', params: { key: this.spaceKey } })
      } else {
        this.$router.push({ name: 'manage-home' })
      }
    },

    async getCurrentViewData(initialLoad = false) {
      if (this.view == 'queue-task') {
        this.getStatusFromURL()
        await this.getQueueTasks()
      } else if (this.view == 'people') {
        await this.getManagers()
      } else if (this.view == 'dashboard') {
        await Promise.all([this.getSpace(), this.getQueues()])
      } else if (this.view == 'settings') {
        await Promise.all([this.getSpace(), this.getDataSchemas()])
      } else {
        await this.getSpace()
      }
      this.setSidebarQueues()

      if (initialLoad) {
        this.saveLocalLastSpace()
        await utils.sleep(250)
        this.initialLoading = false
      }
    },

    setSidebarQueues() {
      this.sidebarQueues = []
      if (this.space.queues && this.space.queues.length) {
        this.space.queues.forEach(queue => {
          this.sidebarQueues.push({
            text: queue.title,
            status: 'letter',
            letter: queue.prefix.substring(0, 2).toUpperCase(),
            value: queue.key,
          })
        })
      }
    },

    // {
    //   "can_edit_task": true,
    //   "is_space_owner": true,
    //   "is_space_manager": true,
    //   "can_edit_space": true,
    //   "is_queue_owner": true,
    //   "is_queue_manager": true,
    //   "can_edit_queue": true,
    //   "is_task_owner": false,
    //   "is_task_manager": false
    // }
    setValidations(validations) {
      this.validations = {}
      if (validations.space) {
        this.validations['can_edit_space'] = Boolean(validations.space.can_edit_space)
        this.validations['is_space_owner'] = Boolean(validations.space.is_space_owner)
        this.validations['is_space_manager'] = Boolean(validations.space.is_space_manager)
      } else {
        this.validations['can_edit_space'] = Boolean(validations.can_edit_space)
        this.validations['is_space_owner'] = Boolean(validations.is_space_owner)
        this.validations['is_space_manager'] = Boolean(validations.is_space_manager)
      }

      if (validations.queue) {
        if (this.queueKey) {
          this.validations['is_queue_owner'] = Boolean(validations.queue[this.queueKey]?.is_queue_owner)
          this.validations['is_queue_owner'] = Boolean(validations.queue[this.queueKey]?.is_queue_owner)
          this.validations['can_edit_queue'] = Boolean(validations.queue[this.queueKey]?.can_edit_queue)
        } else {
          this.validations['queues'] = validations.queue
        }
      } else {
        this.validations['is_queue_owner'] = Boolean(validations.is_queue_owner)
        this.validations['is_queue_owner'] = Boolean(validations.is_queue_owner)
        this.validations['can_edit_queue'] = Boolean(validations.can_edit_queue)
      }

      if (validations.types) {
        this.validations['types'] = validations.types
      }

      this.validations['is_task_owner'] = Boolean(validations.is_task_owner)
      this.validations['is_task_manager'] = Boolean(validations.is_task_manager)
      this.validations['can_edit_task'] = Boolean(validations.can_edit_task)

      console.log('validations :>> ', this.validations)
    },

    async getSpace() {
      this.loading = true

      try {
        const response = await this.api.getSpace(this.spaceKey)
        console.log('getSpace :>> ', response)
        this.space = response.space
        this.users = response.users
        this.space.user = response.users[response.space.user_key]
        this.setValidations(response.validations)
      } catch (error) {
        console.log('Error getSpace :>> ', error)
        this.handleErrors(error, 'Espaço não encontrado.')
      }

      this.loading = false
    },

    async getDataSchemas() {
      this.loading = true

      try {
        const response = await this.api.getDataSchemas(this.spaceKey)
        console.log('getDataSchemas :>> ', response)
        this.schemas = response
      } catch (error) {
        console.log('Error getDataSchemas :>> ', error)
        this.handleErrors(error, 'Espaço não encontrado.')
      }

      this.loading = false
    },

    async getManagers() {
      this.loading = true

      try {
        const response = await this.api.getManagers(this.spaceKey)
        console.log('getManagers :>> ', response)
        this.space = response.space
        this.users = response.users
        this.usersRoles = response.roles
        this.space.user = response.users[response.space.user_key]
        this.setValidations(response.validations)
      } catch (error) {
        console.log('Error getManagers :>> ', error)
        this.handleErrors(error, 'Espaço não encontrado.')
      }

      this.loading = false
    },

    async updateSpace(spaceData) {
      this.savingData = true

      try {
        const response = await this.api.updateSpace(this.spaceKey, {
          title: spaceData.title,
          team_name: spaceData.team_name,
        })
        console.log('updateSpace :>> ', response)
        this.space.title = response.title
      } catch (error) {
        console.log('Error updateSpace :>> ', error)

        this.$buefy.snackbar.open({
          message: 'Ocorreu um erro ao guardar o espaço.',
          type: 'is-danger',
        })
      }

      this.savingData = false
    },

    getStatusFromURL() {
      if (!this.$route.query.f) {
        this.appliedStatus = 'all'
        return
      }
      const appliedFilters = this.$route.query.f.split(',')
      let index = 0
      for (index; index < appliedFilters.length; index++) {
        const [filterKey, filterValue] = appliedFilters[index].split(':')
        if (filterKey == 'status') {
          this.appliedStatus = filterValue
          return
        }
      }
    },

    setUrlParams() {
      const query = {}
      console.log('setUrlParams appliedFilter :>> ', this.appliedFilter)
      if (this.appliedStatus) {
        query['f'] = `status:${this.appliedStatus}`
      }

      this.$router.push({ path: this.$route.path, query: query })
    },

    applyTasksFilter(status, search = null) {
      this.appliedStatus = status
      this.setUrlParams()
      this.getQueueTasks(search)
    },

    async getQueues() {
      this.loading = true

      try {
        const response = await this.api.getQueues(this.spaceKey)
        this.space.queues = response.queues.filter(el => el.can_view != false)
        this.users = { ...this.users, ...response.users }
        this.queuesStats = response.stats
        console.log('getQueues :>> ', response)
      } catch (error) {
        console.log('Error getQueues :>> ', error)
        this.handleErrors(error, 'Espaço não encontrado.')
      }

      this.loading = false
    },

    async createQueue(queueData) {
      this.savingQueuesData = true
      console.log('createQueue :>> ', queueData)
      try {
        const response = await this.api.createQueue(this.space.key, queueData)
        console.log('createQueue :>> ', response)
        if (!this.space.queues) {
          this.space.queues = [response.queue]
        } else {
          this.space.queues.push(response.queue)
        }
        this.validations.queues[response.queue.key] = {
          is_queue_owner: response.validations.is_queue_owner,
          is_queue_manager: response.validations.is_queue_manager,
          can_edit_queue: response.validations.can_edit_queue,
          can_delete_queue: response.validations.can_delete_queue,
        }
      } catch (error) {
        console.error('Error createQueue:', error)
        const errorKey = utils.errors(error).getKey()
        if (errorKey && errorKey == 'QueueTitleAlreadyExists') {
          this.$buefy.dialog.alert({
            title: this.$t('queueTitleAlreadyExists'),
            message: this.$t('queueTitleAlreadyExistsMessage'),
            type: 'is-danger',
          })
        } else if (errorKey && errorKey == 'QueuePrefixAlreadyExists') {
          this.$buefy.dialog.alert({
            title: this.$t('queuePrefixAlreadyExists'),
            message: this.$t('queuePrefixAlreadyExistsMessage'),
            type: 'is-danger',
          })
        } else {
          this.$buefy.snackbar.open({
            message: 'Ocorreu um erro ao tentar criar a fila',
            type: 'is-danger',
          })
        }
      }

      this.savingQueuesData = false
    },

    async updateQueue(queueData) {
      this.savingQueuesData = true

      console.log('updateQueue :>> ', queueData)
      try {
        const response = await this.api.updateQueue(this.space.key, queueData.key, queueData)
        console.log('updateQueue :>> ', response)
        const index = this.space.queues.findIndex(el => el.key == response.queue.key)
        if (index > -1) this.$set(this.space.queues, index, response.queue)
        this.validations.queues[response.queue.key] = {
          is_queue_owner: response.validations.is_queue_owner,
          is_queue_manager: response.validations.is_queue_manager,
          can_edit_queue: response.validations.can_edit_queue,
          can_delete_queue: response.validations.can_delete_queue,
        }
      } catch (error) {
        console.error('Error updateQueue:', error)
        const errorKey = utils.errors(error).getKey()
        if (errorKey && errorKey == 'QueueTitleAlreadyExists') {
          this.$buefy.dialog.alert({
            title: this.$t('queueTitleAlreadyExists'),
            message: this.$t('queueTitleAlreadyExistsMessage'),
            type: 'is-danger',
          })
        } else {
          this.$buefy.snackbar.open({
            message: 'Ocorreu um erro ao tentar criar a fila',
            type: 'is-danger',
          })
        }
      }

      this.savingQueuesData = false
    },

    async deleteQueue(queueKey) {
      this.savingQueuesData = true

      try {
        const response = await this.api.deleteQueue(this.space.key, queueKey)
        console.log('deleteQueue :>> ', response)
        const index = this.space.queues.findIndex(el => el.key == queueKey)
        if (index > -1) this.space.queues.splice(index, 1)
      } catch (error) {
        console.error('Error deleteQueue:', error)
        this.$buefy.snackbar.open({
          message: 'Ocorreu um erro ao tentar apagar a fila',
          type: 'is-danger',
        })
      }

      this.savingQueuesData = false
    },

    pageChanged(page) {
      if (page) this.page = page
      this.getQueueTasks()
    },

    async getQueueTasks(search = null) {
      this.loading = true

      const query = { limit: this.limit, page: this.page }
      query['status'] = this.appliedStatus == 'all' ? null : this.appliedStatus
      if (this.appliedStatus == 'stale') {
        query['stale'] = 2
        delete query['status']
      }
      if (search) query['query'] = search

      try {
        const response = await this.api.getTasks(this.spaceKey, this.queueKey, query)
        this.tasks = response.tasks.tasks
        this.totalPages = response.tasks.pagination.total_pages
        this.page = response.tasks.pagination.current_page
        this.totalResults = response.tasks.pagination.total_items
        this.space = response.space
        this.space.queues = response.queues.filter(el => el.can_view != false)
        this.users = { ...this.users, ...response.users }
        this.setValidations(response.validations)
        console.log('getQueueTasks :>> ', response)
      } catch (error) {
        console.log('Error getQueueTasks :>> ', error)

        this.handleErrors(error, 'Fila não encontrada.')
      }

      this.loading = false
    },

    async updateManagers({ usersAndRoles, type, refresh }) {
      this.savingData = true
      console.log('updateManagers :>> ', usersAndRoles, type, refresh)
      try {
        const response = await this.api.createManagers(this.spaceKey, usersAndRoles)
        if (type == 'spaceOwner') {
          this.$buefy.snackbar.open({
            message: 'Responsável do espaço alterado com sucesso.',
            type: 'is-primary',
          })
          return this.$router.push({ name: 'manage-home' })
        }

        if (response.__errors__) {
          console.error(response.__errors__)
        }
        console.log('updateManagers response :>> ', response)
      } catch (error) {
        console.log('Error createQueueManagers :>> ', error)
        let errorMsg = `Ocorreu um erro ao adicionar os utilizadores ${
          type == 'spaceAdmins' ? 'do espaço' : 'da fila'
        }.`
        if (type == 'spaceOwner') {
          errorMsg = `Ocorreu um erro ao alterar o responsável do espaço.`
        }
        this.$buefy.snackbar.open({
          message: errorMsg,
          type: 'is-danger',
        })
      }

      this.savingData = false

      if (refresh) this.getCurrentViewData()
    },

    async deleteManagers({ usersAndRoles, type, refresh }) {
      this.savingData = true
      console.log('deleteManagers :>> ', usersAndRoles, type, refresh)
      try {
        const response = await this.api.deleteManagers(this.spaceKey, usersAndRoles)
        console.log('deleteManagers response :>> ', response)
      } catch (error) {
        console.log('Error deleteManagers :>> ', error)

        this.$buefy.snackbar.open({
          message: `Ocorreu um erro ao remover os utilizadores ${type == 'spaceAdmins' ? 'do espaço' : 'da fila'}.`,
          type: 'is-danger',
        })
      }
      this.savingData = false
      if (refresh) this.getCurrentViewData()
    },

    updateStatusOnList(task) {
      let index = this.tasks.findIndex(el => el.key == task.key)
      if (index > -1) this.$set(this.tasks, index, task)
      this.task = task
    },

    handleErrors(error, notFoundMessage) {
      console.error('handleErrors :>>', { error, notFoundMessage })
      const errorKey = utils.errors(error).getKey()
      if (errorKey && errorKey == 'Forbidden') {
        this.forbidden = true
      }
      if (errorKey && errorKey == 'NotFound') {
        this.$buefy.snackbar.open({
          message: notFoundMessage,
          type: 'is-danger',
        })
        this.notfound = true
      }
    },

    saveLocalLastSpace() {
      localStorage.setItem(
        'last-space-open',
        JSON.stringify({
          key: this.space.key,
          prefix: this.space.prefix,
          title: this.space.title,
        })
      )
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "loadingSpaceTitle": "Espaço",
    "queuePrefixAlreadyExists": "Prefixo já definido",
    "queuePrefixAlreadyExistsMessage": "Já existe uma fila com o prefixo que tentou definir. Por favor, indique outro para guardar as alterações.",
    "queueTitleAlreadyExists": "Título já existe",
    "queueTitleAlreadyExistsMessage": "Já existe uma fila com o título que tentou definir. Por favor, indique outro para guardar as alterações."
  },
  "en": {
    "loadingSpaceTitle": "Space",
    "queuePrefixAlreadyExists": "Prefix already set",
    "queuePrefixAlreadyExistsMessage": "A queue already exists with the prefix you tried to set. Please specify another one to save your changes.",
    "queueTitleAlreadyExists": "Title already exists",
    "queueTitleAlreadyExistsMessage": "There is already a row with the title you tried to set. Please enter another one to save your changes."
  }
}
</i18n>
