<template>
  <b-dropdown aria-role="list" position="is-bottom-left">
    <template #trigger="{ active }">
      <fw-button
        size="sm"
        class="flex gap-2 font-semibold"
        :class="{
          'opacity-100': active,
        }"
      >
        <div class="max-width-text flex items-center gap-2">
          <fw-icon-filter class="fill-current text-gray-500 h-5 w-5"></fw-icon-filter>
          <span v-if="showFilterLabel">{{ $t('filter') }} {{ selectedFilter ? `(${selectedFilter.label})` : '' }}</span>
        </div>
      </fw-button>
    </template>

    <div class="mb-3 z-10">
      <div v-for="(filter, key) in filters" :key="key">
        <fw-label v-if="filter.label && filter.label.length > 0" class="ml-4 pt-3 pb-2">{{ filter.label }}</fw-label>
        <b-dropdown-item
          v-for="option in filter.options"
          :key="option.key"
          aria-role="menu-item"
          :focusable="true"
          class="font-semibold flex justify-start gap-3 items-center"
          @click.native="selectFilter(option)"
        >
          <div
            class="border-2 border-gray-200 h-4 w-4 rounded-full -mt-0.5"
            :class="{
              'bg-primary bg-opacity-90': selectedFilter.key === option.key,
              'bg-gray-200': selectedFilter.key !== option.key,
            }"
          ></div>
          <div>{{ option.label }}</div>
        </b-dropdown-item>
      </div>
    </div>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    selectedFilter: {
      type: Object,
      default: () => {},
    },

    filters: {
      type: Array,
      default: () => [],
    },
    showFilterLabel: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    selectFilter(filter) {
      this.$emit('filter-changed', filter)
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "applyFilters": "Aplicar filtros",
    "filter": "Filtrar"
  },
  "en": {
    "applyFilters": "Apply filters",
    "filter": "Filter"
  }
}
</i18n>
