<template>
  <ChoosePeopleModal
    :title="$t('chooseSpaceOwner')"
    :instructions="$t('selectSpaceOwner')"
    :multiselect="false"
    :selected="[currentOwner]"
    :show-confirmation="true"
    :auto-close="false"
    :transfer-to="newSpaceOwner ? [newSpaceOwner] : []"
    :confirm-button-text="$t('confirm')"
    :confirm-disabled="!newSpaceOwner"
    :not-alowed-users="notAlowedUsers"
    :endpoint="endpointSearch"
    @selected="newSelection"
    @close="close"
    @confirm="confirmEvent"
  >
    <template #previous>
      <div class="flex flex-col gap-3 px-5 mt-1.5">
        <div class="flex-shrink-0 rounded-xl bg-gray-100 px-3 py-2">
          <fw-label v-if="!newSpaceOwner">{{ $t('newOwner') }}</fw-label>
          <fw-label v-else>{{ $t('oldOwner') }}</fw-label>
          <fw-person :person="currentOwner"></fw-person>
          <div v-if="newSpaceOwner" class="flex-shrink-0">
            <fw-label class="mt-2">{{ $t('newOwner') }}</fw-label>
            <fw-person :person="newSpaceOwner"></fw-person>
          </div>
        </div>
      </div>
    </template>
  </ChoosePeopleModal>
</template>

<script>
import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal'

export default {
  name: 'ChooseSecretariatModal',
  components: { ChoosePeopleModal },
  props: {
    space: {
      type: Object,
      required: true,
    },
    currentOwner: {
      type: Object,
      required: true,
    },
    notAlowedUsers: {
      type: Array,
      default: function() {
        return []
      },
    },
  },
  data() {
    return {
      newSpaceOwner: null,
      transferMessage: null,
    }
  },
  computed: {
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    },
  },

  methods: {
    async endpointSearch(params) {
      return await this.api.spaceUsersSearch(this.space.key, params)
    },

    close() {
      this.$emit('close')
    },

    newSelection(users) {
      if (users.length > 0) {
        this.newSpaceOwner = users[0]
      } else {
        this.newSpaceOwner = null
      }
    },

    confirmEvent() {
      // TODO: Add confirm message
      if (this.newSpaceOwner) {
        this.$emit('selected', this.newSpaceOwner)
        this.close()
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {

    "chooseSpaceOwner": "Escolha o responsável do espaço",
    "selectSpaceOwner": "Selecione o utilizador que quer como responsável do espaço:",
    "currentOwner": "Responsável atual",
    "oldOwner": "Responsável antigo",
    "newOwner": "Novo responsável"
  },
  "en":{
    "confirm": "Confirm",
    "chooseSpaceOwner": "Choose the head of the space",
    "selectSpaceOwner": "Select the user you want as head of space:",
    "currentOwner": "Current head of space",
    "oldOwner": "Old head of space",
    "newOwner": "New head of space"

  }

}
</i18n>
