<template>
  <div v-if="item">
    <fw-panel :title="item.key ? $t('editSchema') : $t('newSchema')" featured custom-class="mb-5 flex flex-col gap-5">
      <div>
        <fw-label>{{ $t('title.label') }}</fw-label>
        <TextInput
          v-model="item.title"
          :minlength="2"
          :maxlength="250"
          :placeholder="$t('title.placeholder')"
          :class="{
            error: $v.item.title.$error,
          }"
        >
        </TextInput>

        <fw-tip v-if="$v.item.title.$error" error>
          <span v-if="!$v.item.title.required">{{ $t('title.required') }}</span>
          <span v-else>{{ $t('title.invalid') }}</span>
        </fw-tip>
      </div>

      <div>
        <fw-label>Label</fw-label>
        <TextInput v-model="item.schema[0].label" :minlength="2" :maxlength="250" :placeholder="'label'"> </TextInput>
      </div>
      <div>
        <fw-label>Type</fw-label>
        <b-radio-button
          v-model="item.schema[0].input_type"
          native-value="checkbox"
          type="is-danger is-light is-outlined"
        >
          Escolha multipla
        </b-radio-button>

        <b-radio-button v-model="item.schema[0].input_type" native-value="text" type="is-success is-light is-outlined">
          Texto
        </b-radio-button>
      </div>

      <div v-if="item.schema[0].input_type == 'checkbox'">
        <fw-label>Can Select Multiple ?</fw-label>
        <b-switch v-model="schema[0].multiple"> </b-switch>
      </div>
    </fw-panel>

    <fw-panel-info debug label="type data (raw)">
      <json-viewer :value="{ item }"></json-viewer>
    </fw-panel-info>

    <div class="flex-1 flex justify-end items-center mt-3">
      <fw-button type="link-muted" class="mr-4" @click.native="$emit('close')">{{ $t('cancel') }}</fw-button>
      <fw-button type="primary" @click.native="saveSchema">{{ $t('save') }}</fw-button>
    </div>
  </div>
</template>

<script>
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import { required, maxLength, minLength } from 'vuelidate/lib/validators'
// import Multiselect from 'vue-multiselect'

export default {
  components: {
    TextInput,
    // Multiselect,
  },

  props: {
    item: {
      type: Object,
      default: () => {
        return {
          key: null,
          title: '',
          queue_keys: [],
          schema: [
            {
              label: 'Escolha coisas',
              input_type: 'checkbox',
              multiple: true,
              options: ['123', '456', '789'],
            },
            {
              label: 'Escolha coisas 2',
              input_type: 'text',
            },
          ],
        }
      },
    },
    queues: {
      type: Array,
      default: () => [],
    },
  },

  validations: {
    item: {
      title: { required, min: minLength(2), max: maxLength(250) },
      schema: { required },
    },
  },

  mounted() {
    console.log('this.item', this.item)
  },

  methods: {
    customLabel({ prefix, title }) {
      return `${prefix.toUpperCase()} - ${title}`
    },

    saveSchema() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$emit('save', this.type)
      this.$emit('close')
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "charsLimit": "Limite de {limit} caracteres.",
    "thereAreErrors": "Existem erros de formulário",
    "newSchema": "Novo ",
    "editSchema": "Editar ",
    "save": "Guardar",
    "close": "Fechar",
    "edit": "Editar",
    "cancel": "Cancelar",
    "delete": "Apagar",
    "title": {
      "label": "Título",
      "placeholder": "Título",
      "required": "Insira um título",
      "invalid": "Insira um título válido"
    },
    "queue": {
      "label": "Fila",
      "placeholder": "Escolha uma fila",
      "required": "Escolha uma fila",
      "invalid": "Escolha uma fila válida"
    },
    "apps": {
      "label": "Applicações",
      "placeholder": "Escolha as applicações"
    },
    "description": {
      "label": "Descrição",
      "placeholder": "Descrição",
      "required": "Insira uma descrição",
      "invalid": "Insira uma descrição válida"
    },
    "isPublic": {
      "label": "Public label",
      "placeholder": "Public",
      "description": "Public description"
    }
  },
  "en": {
    "charsLimit": "Limit of {limit} characters.",
    "thereAreErrors": "There are errors in the form",
    "newSchema": "New Schema",
    "editSchema": "Edit Schema",
    "save": "Save",
    "close": "Close",
    "edit": "Edit",
    "cancel": "Cancel",
    "delete": "Delete",
    "title": {
      "label": "Title",
      "placeholder": "Title",
      "required": "Enter a title",
      "invalid": "Enter a valid title"
    },
    "queue": {
      "label": "Queue",
      "placeholder": "Choose a queue",
      "required": "Choose a queue",
      "invalid": "Choose a valid queue"
    },
    "apps": {
      "label": "Applications",
      "placeholder": "Choose the applications"
    },
    "description": {
      "label": "Description",
      "placeholder": "Description",
      "required": "Enter a description",
      "invalid": "Enter a valid description"
    },
    "isPublic": {
      "label": "Public label",
      "placeholder": "Public",
      "description": "Public description"
    }
  }
}
</i18n>
