<template>
  <div class="h-full flex">
    <div class="flex-1 overflow-auto p-5">
      <div class="max-w-screen-md mx-auto flex flex-col items-stretch gap-10">
        <ContentLoader v-if="loading" />
        <div v-else class="flex flex-col gap-2">
          <!-- Task details -->
          <fw-heading v-if="queuePrefix && taskId" size="h4" muted>
            {{ queuePrefix | uppercase }} / {{ taskId | uppercase }}
          </fw-heading>
          <fw-heading v-if="task" size="h1">{{ task.title }}</fw-heading>
          <div>
            <fw-label>{{ $t('user') }}</fw-label>
            <fw-person :person="users[task?.user_key]" no-style paddingless class="mt-1" />
          </div>
        </div>
        <fw-panel v-if="!loading && taskId && task" title="Metadados" title-size="h4" boxed="sm">
          <div class="flex flex-col gap-6 flex-1">
            <div class="-mt-2">
              <fw-label>{{ $t('description') }}</fw-label>
              <div class="html-content" v-html="task.description"></div>
            </div>

            <div v-if="task.type && task.type.title">
              <fw-label>{{ $t('subject') }}</fw-label>
              <div>{{ task.type.title }}</div>
            </div>

            <div v-if="task.context.type">
              <fw-label>{{ $t(task.context.type) }}</fw-label>
              <div v-if="procedure">{{ procedure.prefix }}{{ procedure.code }}</div>
              <div v-else>{{ task.context.key }}</div>
            </div>

            <div v-if="task.data && !isObjectEmpty(task.data)">
              <fw-label>{{ $t('taskData') }}</fw-label>
              <json-viewer :value="task.data" preview-mode></json-viewer>
            </div>

            <div v-if="debugmode && task.request_details && !isObjectEmpty(task.request_details)">
              <fw-label>Debug metadata</fw-label>
              <json-viewer :value="task.request_details" preview-mode></json-viewer>
            </div>

            <div v-if="task.ratings?.length && validations?.is_space_manager">
              <fw-label>Ratings</fw-label>
              <b-rate
                v-for="rating in task.ratings"
                :key="rating.key"
                :show-score="true"
                :value="rating.score"
                :spaced="true"
                :disabled="true"
              >
              </b-rate>
            </div>

            <div>
              <fw-label>{{ $t('files') }}</fw-label>

              <div v-if="task.files && task.files.length > 0">
                <RecordFileEntry
                  v-for="file in task.files"
                  :key="file.key"
                  paddingless
                  class="mb-2"
                  :can-edit="false"
                  :allow-classified="false"
                  :file="file"
                  :can-remove="false"
                  @download="downloadFile(file)"
                />
              </div>
              <fw-panel-info v-else type="basic" clean>{{ $t('noFiles') }}.</fw-panel-info>
            </div>
          </div>
          <!-- /Task details -->
        </fw-panel>
        <fw-panel v-if="!loading" :title="$t('activity')">
          <b-tabs v-model="activeTab" :animated="false" @input="getTabData">
            <b-tab-item :label="$t('messages')" value="messages">
              <PanelMessages
                :users="users"
                :messages="messages"
                :pagination="paginationMessages"
                :loading="loadingMessages"
                :show-public-private-toggle="true"
                :force-hide-private-messages="false"
                :can-add-message="canEdit"
                :default-add-message-is-private="true"
                @page-changed="messagesPageChanged"
                @add-message="addMessage"
                @update-message="updateMessage"
                @delete-message="deleteMessage"
              />
            </b-tab-item>
            <b-tab-item :label="$t('logs')" value="logs">
              <PanelTaskActivity
                :task-id="taskId"
                :space-key="spaceKey"
                :queue-key="queueKey"
                :activity="logs"
                :users="users"
                :pagination="paginationActivity"
                :loading="loadingActivity"
                class="bg-white rounded-xl"
                @page-changed="activityPageChanged"
              ></PanelTaskActivity>
            </b-tab-item>
          </b-tabs>
        </fw-panel>
      </div>
    </div>

    <!-- Right sidebar -->
    <div v-if="task" class="w-80 overflow-auto border-l border-gray-200">
      <div class="p-4 flex flex-col gap-3">
        <div class="flex flex-col border-b pb-4">
          <fw-label>{{ $t('status.label') }}</fw-label>
          <div>
            <b-dropdown v-if="validations.can_edit_task" aria-role="list" expanded>
              <fw-button-dropdown
                slot="trigger"
                aria-role="listitem"
                :type="statusType[task.status]"
                :label="$t(`status.${task.status}`)"
                expanded
              />

              <b-dropdown-item
                v-for="status in availableStatus"
                ref="actionitem"
                :key="status"
                has-link
                class="paddingless"
                aria-role="menuitem"
              >
                <fw-button type="basic-action" class="w-full my-0.5 text-left" @click.native="changeStatus(status)">{{
                  $t(`status.${status}`)
                }}</fw-button>
              </b-dropdown-item>
            </b-dropdown>
            <div v-else>
              <fw-tag :type="statusTag?.[task?.status]" size="md" class="px-5 py-1" expanded>{{
                $t(`status.${task.status}`)
              }}</fw-tag>
            </div>
          </div>
        </div>

        <div class="border-b pb-4 flex flex-col gap-1">
          <div class="flex gap-2 justify-between">
            <fw-label>{{ $t('assignees') }}</fw-label>
            <fw-button v-if="canEdit" type="link" @click.native="chooseAssignees = true">{{
              $t('addAssignees')
            }}</fw-button>
          </div>

          <div v-if="task.managers && task.managers.length" class="flex flex-col gap-4">
            <div v-for="user_key in task.managers" :key="user_key" class="flex items-center gap-1">
              <div class="flex-1 flex gap-3 items-center">
                <fw-person :person="users[user_key]" no-style paddingless />
              </div>
              <div v-if="canEdit" class="flex-shrink-0 opacity-70 hover:opacity-100">
                <fw-button type="xlight" label="Remover" @click.native="deleteAssignee(user_key)">
                  <fw-icon-close-circle class="h-5 w-5" />
                </fw-button>
              </div>
            </div>
          </div>
          <div v-else class="text-gray-500 text-sm">{{ $t('notDefined') }}.</div>
        </div>

        <div class="border-b pb-4">
          <div>
            <fw-label>Operações</fw-label>
          </div>
          <div>
            <fw-button type="link" @click.native="chooseNewQueue = true"> {{ $t('move') }}</fw-button>
          </div>
        </div>

        <div class="text-gray-500">
          <div v-if="task.created_at">
            <fw-label marginless>{{ $t('createdAt') }}</fw-label>
            <div>{{ task.created_at | formatDateTime }}</div>
          </div>

          <div v-if="task.open_at">
            <fw-label marginless>{{ $t('openAt') }}</fw-label>
            <div>{{ task.open_at | formatDateTime }}</div>
          </div>

          <div v-if="task.close_at">
            <fw-label marginless>{{ $t('closedAt') }}</fw-label>
            <div class="flex items-center gap-1">
              <span>{{ task.close_at | formatDateTime }}</span>
              <fw-icon-checkbox-circle class="w-5 h-5 text-primary" />
            </div>
          </div>

          <div v-if="task.archived_at">
            <fw-label marginless>{{ $t('archivedAt') }}</fw-label>
            <div>{{ task.archived_at | formatDateTime }}</div>
          </div>
        </div>

        <div v-if="task.associations">
          <fw-label marginless>{{ $t('linkedTasks') }}</fw-label>
          <template v-for="association in task.associations">
            <div v-if="association.task_key == task.key" :key="association.key">
              <fw-label v-if="association.context == 'moved_to'" marginless size="xs" color="text-gray-400"
                >{{ $t('movedTo') }}:</fw-label
              >

              <a
                :href="
                  `/manage/spaces/${spaceKey}/queues/${association.linked_task_queue_key}/tasks/${association.linked_task_id}`
                "
                target="_blank"
                class="text-primary rounded-lg font-bold transition-colors duration-150 border border-transparent focus:outline-none"
              >
                #{{ association.linked_task_id | uppercase }}
              </a>
            </div>
            <div v-else :key="association.key">
              <fw-label v-if="association.context == 'moved_to'" marginless size="xs" color="text-gray-400"
                >{{ $t('movedFrom') }}:</fw-label
              >
              <a
                :href="`/manage/spaces/${spaceKey}/queues/${association.task_queue_key}/tasks/${association.task_id}`"
                target="_blank"
                class="text-primary rounded-lg font-bold transition-colors duration-150 border border-transparent focus:outline-none"
              >
                #{{ association.task_id | uppercase }}
              </a>
            </div>
          </template>
        </div>

        <BlockHeaderDebug>
          <fw-panel-info debug label="Task (raw)">
            <json-viewer :value="{ task, user_agents, users, validations }"></json-viewer>
          </fw-panel-info>
        </BlockHeaderDebug>
      </div>
    </div>
    <fw-modal :active.sync="chooseAssignees" size="auto" boxed="xs" @close="closeModal">
      <ModalChooseTaskAssignees
        v-if="chooseAssignees"
        :managers="task.managers"
        :space-key="spaceKey"
        :queue-key="queueKey"
        :task-id="taskId"
        :users="users"
        :available-users="assigneesList"
        @close="closeModal"
        @add="addNewAssignees"
      ></ModalChooseTaskAssignees>
    </fw-modal>
    <fw-modal :active.sync="chooseNewQueue" size="auto" boxed="sm" @close="closeModal">
      <ModalMoveTask
        v-if="chooseNewQueue"
        :queue-key="queueKey"
        :queues="space.queues"
        :task-id="taskId"
        @close="closeModal"
        @save="moveTask"
      ></ModalMoveTask>
    </fw-modal>
  </div>
</template>

<script>
import PanelTaskActivity from '@/components/panels/PanelTaskActivity'
import ModalChooseTaskAssignees from '@/components/modals/ModalChooseTaskAssignees'
import ModalMoveTask from '@/components/modals/ModalMoveTask'
import BlockHeaderDebug from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockHeaderDebug'
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'
import ContentLoader from '@/fw-modules/fw-core-vue/ui/components/animation/ContentLoader'
import PanelMessages from '@/fw-modules/fw-core-vue/ui/components/panels/PanelMessages'

import { TASK_STATUS_BUTTONS } from '@/utils/index.js'
import ServiceApply from '@/fw-modules/fw-core-vue/apply/services/ServiceApply'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import { TASK_STATUS_TAG } from '../../utils'

export default {
  components: {
    PanelTaskActivity,
    PanelMessages,
    BlockHeaderDebug,
    ModalChooseTaskAssignees,
    ModalMoveTask,
    RecordFileEntry,
    ContentLoader,
  },

  props: {
    taskId: {
      type: String,
      required: true,
    },
    queuePrefix: {
      type: String,
      required: true,
    },
    queueKey: {
      type: String,
      required: true,
    },
    spaceKey: {
      type: String,
      required: true,
    },
    space: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      task: null,
      loadingMessages: false,
      loadingActivity: false,
      loading: true,
      activeTab: 'messages',
      procedure: null,
      availableAssignees: [],
      user_agents: {},
      users: {},
      validations: {},
      chooseAssignees: false,
      chooseNewQueue: false,
      logs: [],
      messages: [],
      statusType: Object.freeze(TASK_STATUS_BUTTONS),
      statusTag: Object.freeze(TASK_STATUS_TAG),
      paginationActivity: {
        // TODO:
        page: 1,
        totalResults: 0,
        totalPages: 1,
        limit: 50,
      },
      paginationMessages: {
        // TODO:
        page: 1,
        totalResults: 0,
        totalPages: 1,
        limit: 50,
      },
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    debugmode() {
      return Boolean(localStorage.getItem('fw-debug'))
    },

    loggedUser() {
      return this.$store.getters.getUser
    },

    availableStatus() {
      return ['new', 'open', 'canceled', 'closed', 'review', 'paused', 'archived'].filter(el => el != this.task.status)
    },

    assigneesList() {
      return this.task.managers
        ? this.availableAssignees.filter(el => !this.task.managers.includes(el.key))
        : this.availableAssignees
    },

    canEdit() {
      return (
        (this.validations?.is_space_manager ||
          this.validations?.is_space_owner ||
          this.validations?.is_queue_manager ||
          this.validations?.is_task_manager) &&
        this.validations.can_edit_task
      )
    },
  },

  mounted() {
    this.getAssigneesList()
    this.getTask()
    this.getTabData(this.activeTab)
  },

  methods: {
    goToTasksList() {
      this.$router.push({ name: 'manage-space-queue', params: { key: this.spaceKey, queueKey: this.queueKey } })
    },

    goToTask(queueKey, taskId) {
      this.$router.push({
        name: 'manage-space-queue-task',
        params: { key: this.spaceKey, queueKey: queueKey, taskId: taskId },
      })
    },

    isObjectEmpty(obj) {
      return utils.isObjectEmpty(obj)
    },

    closeModal() {
      this.chooseAssignees = false
      this.chooseNewQueue = false
    },

    downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },

    async deleteAssignee(userKey) {
      console.log('deleteAssignee :>> ', userKey)
      try {
        const response = await this.api.deleteTaskManagers(this.spaceKey, this.queueKey, this.taskId, [userKey])
        console.log('deleteTaskManagers :>> ', response)
        if (response.removed_managers)
          this.task.managers = this.task.managers.filter(el => el != response.removed_managers)
      } catch (error) {
        console.log('Error deleteTaskManagers', error)
        this.$buefy.dialog.alert({
          title: this.$t('errorOccurred.title'),
          message: this.$t('errorOccurred.message'),
          type: 'is-danger',
        })
      }
    },

    async getAssigneesList() {
      try {
        const response = await this.api.getTaskManagers(this.spaceKey, this.queueKey, this.taskId)
        console.log('getTaskManagers :>> ', response)
        this.availableAssignees = response
      } catch (error) {
        console.log('Error getTaskManagers', error)
      }
    },

    async addNewAssignees(newManagersKeys) {
      try {
        const response = await this.api.createTaskManagers(this.spaceKey, this.queueKey, this.taskId, newManagersKeys)
        console.log('createTaskManagers :>> ', response)
        if (this.task.managers) this.task.managers = [...this.task.managers, ...Object.keys(response.new_managers)]
        else this.task.managers = Object.keys(response.new_managers)
        this.users = { ...this.users, ...response.users }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: this.$t('errorOccurred.title'),
          message: this.$t('errorOccurred.message'),
          type: 'is-danger',
        })
        console.log('Error createTaskManagers', error)
      }
    },

    async getTask() {
      this.loading = true

      try {
        const response = await this.api.getTask(this.spaceKey, this.queueKey, this.taskId)
        console.log('getTask :>> ', response)
        this.validations = response.validations
        this.users = { ...this.users, ...response.users }
        this.user_agents = response.user_agents
        this.task = response.task
        if (this.task.context.key && this.task.context.type == 'procedure') this.getProcedure(this.task.context.key)
      } catch (error) {
        console.log('Error getTask :>> ', error)
      }

      this.loading = false
    },

    changeStatus(newStatus) {
      if (newStatus === 'closed') {
        this.$buefy.dialog.confirm({
          type: 'is-primary',
          title: 'Fechar tarefa',
          message: `<p>Tem a certeza que deseja fechar a tarefa?</p>
            <p class="text-sm mt-1">O requerente será notificado desta ação e convidado classificar a sua experiência.
            A tarefa será aberta caso o requerente deixe uma nova mensagem.</p>`,
          confirmText: 'Confirmar',
          cancelText: 'Cancelar',
          onConfirm: async () => {
            this.doChangeStatus(newStatus)
          },
        })
      } else {
        this.doChangeStatus(newStatus)
      }
    },

    async doChangeStatus(newStatus) {
      console.log('changeStatus :>> ', newStatus)
      this.loading = true

      utils.tryAndCatch(
        this,
        async () => {
          const result = await this.api.changeTaskStatus(this.spaceKey, this.queueKey, this.taskId, newStatus)
          console.log('result :>> ', result)
          this.task.status = result.status
          this.task.open_at = result.open_at
          this.task.closed_at = result.closed_at
          this.task.archived_at = result.archived_at
          this.task.deleted_at = result.deleted_at
          this.task.updated_at = result.updated_at
          this.$emit('task-updated', result)
        },
        () => {
          this.loading = false
        },
        () => {
          this.loadingError = true
        }
      )
    },

    moveTask(newQueue) {
      this.loading = true
      this.loadingError = false
      console.log('moveTask newQueue :>> ', newQueue)

      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.moveTaskToQueue(this.spaceKey, this.queueKey, this.taskId, newQueue.key)
          console.log('moveTaskToQueue :>> ', response)
          this.goToTask(response.queue_key, response.id)
        },
        null,
        () => {
          this.loadingError = true
        }
      )

      this.closeModal()
      this.loading = false
    },

    async getTabData(tab) {
      console.log('getTabData :>> ', tab)
      if (tab == 'messages') this.getTaskMessages()
      else if (tab == 'logs') this.getTaskActivity()
    },

    async getTaskMessages() {
      this.loadingMessages = true

      try {
        console.log('getTaskMessages params :>> ', this.paginationMessages)
        const response = await this.api.getTaskMessages(this.spaceKey, this.queueKey, this.taskId, {
          page: this.paginationMessages.page,
          limit: this.paginationMessages.limit,
        })
        console.log('getTaskMessages :>> ', response)
        this.messages = response.messages
        this.users = { ...this.users, ...response.users }
        this.paginationMessages = {
          limit: response.pagination.active_limit,
          page: response.pagination.current_page,
          totalResults: response.pagination.total_items,
          totalPages: response.pagination.total_pages,
        }
      } catch (e) {
        console.log('Error getTaskMessages :>> ', e)
      }

      this.loadingMessages = false
    },

    messagesPageChanged(page) {
      console.log('messagesPageChanged :>> ', page)
      this.paginationMessages.page = page
      this.getTaskMessages()
    },

    async getTaskActivity() {
      this.loadingActivity = true

      try {
        console.log('getTaskActivity params :>> ', this.paginationActivity)
        const response = await this.api.getTaskActivity(this.spaceKey, this.queueKey, this.taskId, {
          page: this.paginationActivity.page,
          limit: this.paginationActivity.limit,
          sort: 'created_at',
          direction: 'desc',
        })
        console.log('getTaskActivity :>> ', response)
        this.logs = response.logs
        this.users = { ...this.users, ...response.users }
        this.paginationActivity = {
          limit: response.pagination.active_limit,
          page: response.pagination.current_page,
          totalResults: response.pagination.total_items,
          totalPages: response.pagination.total_pages,
        }
      } catch (e) {
        console.log('Error getTaskActivity :>> ', e)
      }

      this.loadingActivity = false
    },

    activityPageChanged(page) {
      console.log('activityPageChanged :>> ', page)
      this.paginationActivity.page = page
      this.getTaskActivity()
    },

    async getProcedure(key) {
      console.log('getProcedure by key :>> ', key)
      try {
        const result = await ServiceApply.getProcedure(key, { by_key: true })
        console.log('PROCEDURE', result)
        this.procedure = result
      } catch (e) {
        console.error(e)
      }
    },

    addedMessage(response) {
      this.messages.unshift(response.message)
      this.users = { ...this.users, [this.loggedUser.key]: this.loggedUser }
      this.task = response.task
      this.$emit('task-updated', response.task)
    },

    updatedMessage(response) {
      let index = this.messages.findIndex(el => el.key == response.key)
      if (index > -1) this.$set(this.messages, index, response)
    },

    deleteMessage(messageData) {
      this.savingMessage = true
      this.savingMessageError = false
      console.log('save messageData :>> ', messageData)

      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.deleteTaskMessages(this.spaceKey, this.queueKey, this.taskId, messageData.key)
          console.log('deleteTaskMessages :>> ', response)
          this.messages = this.messages.filter(el => el.key != messageData.key)
        },
        null,
        () => {
          this.savingMessageError = true
        }
      )

      this.closeModal()
      this.savingMessage = false
    },

    async addMessage(newMessage) {
      this.savingMessage = true
      this.savingMessageError = false
      console.log('add messageKey :>> ', newMessage)

      try {
        const response = await this.api.addTaskMessages(this.spaceKey, this.queueKey, this.taskId, newMessage)
        console.log('response :>> ', response)
        this.addedMessage(response)
      } catch (error) {
        console.log('error :>> ', error)
        this.savingMessageError = true
        this.$buefy.snackbar.open({
          message: this.$t('errorOccuredSaving'),
          type: 'is-warning',
          position: 'is-top-right',
          indefinite: true,
          duration: 2000,
          queue: false,
        })
      }

      this.closeModal()
      this.savingMessage = false
    },

    async updateMessage(messageData) {
      this.savingMessage = true
      this.savingMessageError = false
      console.log('save messageData :>> ', messageData)

      try {
        const response = await this.api.updateTaskMessages(
          this.spaceKey,
          this.queueKey,
          this.taskId,
          messageData.key,
          messageData
        )
        this.updatedMessage(response)
      } catch (error) {
        console.log('error :>> ', error)
        this.savingMessageError = true
        this.$buefy.snackbar.open({
          message: this.$t('errorOccuredSaving'),
          type: 'is-warning',
          position: 'is-top-right',
          indefinite: true,
          duration: 2000,
          queue: false,
        })
      }

      this.closeModal()
      this.savingMessage = false
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "status": {
      "label": "Estado",
      "new": "Novo",
      "open": "Aberto",
      "canceled": "Cancelado",
      "blocked": "Bloqueado",
      "closed": "Fechado",
      "deleted": "Apagado",
      "archived": "Arquivado",
      "review": "Em revisão",
      "paused": "Em pausa"
    },
    "queue": "Fila",
    "user": "Requerente",
    "noFiles": "Não foram inseridos ficheiros",
    "files": "Ficheiros",
    "procedure": "Procedimento",
    "subject": "Assunto",
    "logs": "Auditoria",
    "activity": "Atividade",
    "description": "Mensagem",
    "title": "Titulo",
    "createdAt": "Criado em",
    "openAt": "Aberto em",
    "closedAt": "Fechado em",
    "messages": "Mensagens",
    "assignees": "Responsáveis",
    "addAssignees": "Adicionar",
    "notDefined": "Não definido",
    "actions": "Ações",
    "move": "Mover para outra fila",
    "errorOccurred": {
      "title": "Ocorreu um erro",
      "message": "Ocorreu um erro não esperado ao guardar a task."
    },
    "taskData": "Informação",
    "close": "Fechar",
    "cancel": "Cancelar",
    "delete": "Eliminar",
    "send": "Enviar",
    "newMessage": "Nova mensagem",
    "privateMessages": "Mensagem privada",
    "selectThisOption": "Selecione esta opção para os mensagems serem apenas vísiveis por responsáveis associados a justificação.",
    "noMessages": "Sem mensagems",
    "addMessage": "Adicionar nova mensagem",
    "deleteFileConfirm": "Tem a certeza que deseja eliminar o ficheiro?",
    "uploadFiles": "Carregar ficheiros",
    "deleteMessageConfirm": "Tem a certeza que deseja eliminar a mensagem?",
    "linkedTasks": "Linked tasks",
    "movedTo": "Movido para",
    "movedFrom": "Movido de",
    "errors": {
      "requiredMessage": "Insira um mensagem",
      "invalidMessage": "mensagem inválido"
    },
    "errorOccuredSaving": "Ocorreu um erro ao tentar enviar a mensagem"
  },
  "en": {
    "status": {
      "label": "Status",
      "new": "New",
      "open": "Open",
      "canceled": "Canceled",
      "blocked": "Blocked",
      "closed": "Closed",
      "deleted": "Deleted",
      "archived": "Archived",
      "review": "In review",
      "paused": "Paused"
    },
    "taskData": "Data",
    "queue": "Queue",
    "user": "User",
    "noFiles": "No files",
    "files": "Files",
    "procedure": "Procedure",
    "subject": "Subject",
    "logs": "Logs",
    "activity": "Activity",
    "description": "Message",
    "title": "Title",
    "createdAt": "Created at",
    "openAt": "Opened at",
    "closedAt": "Closed at",
    "assignees": "Assignees",
    "messages": "Messages",
    "addAssignees": "Add",
    "notDefined": "Not defined",
    "actions": "Actions",
    "move": "Move",
    "archivedAt": "Archived at",
    "linkedTasks": "Linked tasks",
    "movedTo": "Moved to",
    "movedFrom": "Moved from",
    "errorOccurred": {
      "title": "An error has occurred",
      "message": "An unexpected error occurred while saving the task."
    },
    "close": "Close",
    "cancel": "Cancel",
    "delete": "Delete",
    "send": "Send",
    "newMessage": "New message",
    "privateMessages": "Private message",
    "selectThisOption": "Select this option for messages to be visible only by officers associated with justification.",
    "noMessages": "No messages",
    "addMessage": "Add new message",
    "deleteFileConfirm": "Are you sure you want to delete the file?",
    "uploadFiles": "Upload files",
    "errors": {
      "requiredMessage": "Enter a message",
      "invalidMessage": "Invalid message"
    },
    "errorOccuredSaving": "Ocorreu um erro ao tentar enviar a mensagem"
  }
}
</i18n>
