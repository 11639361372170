<template>
  <button
    :disabled="disabled"
    class="gap-4 relative rounded-lg px-3 py-2.5 hover:bg-gray-200 hover:bg-opacity-50 group w-full flex items-center text-left"
    :class="{ 'pointer-events-none': disabled }"
    @click="$emit('open', queue)"
  >
    <div class="flex-1">
      <div class="flex-shrink-0 font-medium flex items-center gap-2">
        <fw-icon-cube class="w-5 h-5 text-gray-500" />
        <span class="text-gray-500">{{ queue.prefix | uppercase }}</span>
        <v-clamp autoresize :max-lines="1" class="font-semibold">
          {{ queue.title }}
        </v-clamp>
        <slot name="secondline"></slot>
      </div>
    </div>
    <div v-if="showStats" class="flex gap-3">
      <div>
        <fw-tag :type="stats?.new ? 'primary' : 'xlight'" :counter="stats?.new ?? '0'" expanded align="left"
          >Novas</fw-tag
        >
      </div>
      <div>
        <fw-tag :type="stats?.new ? 'light-primary' : 'xlight'" :counter="stats?.open ?? '0'">Abertas</fw-tag>
      </div>
      <div>
        <fw-tag type="xlight" :counter="stats?.closed ?? '0'">Fechadas</fw-tag>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    queue: {
      type: Object,
      default: () => {},
    },
    stats: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => {},
    },
    minimal: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showStats: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "createdAt": "Criado em"
  },
  "en": {
    "createdAt": "Created at"
  }
}
</i18n>
