<template>
  <SidebarBase language-switcher sticky>
    <template #main>
      <div class="flex-1">
        <ButtonSidebar
          :active="view == 'dashboard'"
          :label="$t('space')"
          icon="dashboard"
          @click.native="goTo('manage-space')"
        />

        <ButtonSidebar
          v-if="queues && queues.length"
          :active="isQueueView"
          icon="cube"
          :label="$t('queues')"
          @click.native="goTo('manage-space-queues')"
        >
          <template #suffix>
            <fw-icon-chevron-up v-if="isQueueView" class="w-4 h-4" />
            <fw-icon-chevron-down v-else class="w-4 h-4" />
          </template>
        </ButtonSidebar>

        <VerticalSteps
          v-if="isQueueView"
          :steps="queues"
          :current-step="currentQueue"
          class="mb-2"
          :truncate-text="true"
          @clicked="goToQueue"
        />

        <ButtonSidebar
          :active="view == 'people'"
          :label="$t('people')"
          icon="people"
          @click.native="goTo('manage-space-people')"
        >
        </ButtonSidebar>

        <ButtonSidebar
          :label="$t('settings')"
          :active="view == 'settings'"
          icon="settings"
          @click.native="goTo('manage-space-settings')"
        />

        <ButtonSidebar
          v-if="validations.can_edit_space"
          :label="$t('activity')"
          :active="view == 'activity'"
          icon="activity"
          @click.native="goTo('manage-space-activity')"
        />

        <ButtonSidebar
          v-if="validations.can_edit_space"
          :label="$t('notifications')"
          :active="view == 'notifications'"
          icon="message-sent"
          @click.native="goTo('manage-space-notifications')"
        />
      </div>
    </template>
  </SidebarBase>
</template>

<script>
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'
import VerticalSteps from '@/fw-modules/fw-core-vue/ui/components/buttons/VerticalSteps'

export default {
  components: {
    ButtonSidebar,
    SidebarBase,
    VerticalSteps,
  },

  props: {
    currentQueue: {
      type: String,
    },

    queues: {
      type: Array,
      default: () => [],
    },

    validations: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    isQueueView() {
      return this.view == 'queues' || this.view == 'queue-task' || this.view == 'queue-tasks'
    },

    view() {
      return this.$route.meta.view ?? 'dashboard'
    },

    spaceKey() {
      return this.$route.params.key
    },
  },

  methods: {
    goTo(routeName) {
      this.$router.push({ name: routeName, params: { key: this.spaceKey } })
    },

    goToQueue(queueKey) {
      this.$router.push({ name: 'manage-space-queue', params: { key: this.spaceKey, queueKey: queueKey } })
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "space": "Espaço",
    "activity": "Atividade",
    "people": "Pessoas",
    "queues": "Filas",
    "notifications": "Notificações",
    "settings": "Configurações"
  },
  "en": {
    "space": "Space",
    "activity": "Activity",
    "people": "People",
    "queues": "Queues",
    "notifications": "Notifications",
    "settings": "Settings"
  }
}
</i18n>
