<template>
  <div>
    <ContentLoader v-if="loading" />
    <template v-if="activity && activity.length">
      <CardActivityLog
        v-for="log in activity"
        :key="log.key"
        :log="log"
        :users="users"
        :expandable="true"
        row
      ></CardActivityLog>
    </template>

    <fw-panel-info v-else centered empty> {{ $t('noActivity') }}. </fw-panel-info>

    <BlockPagination
      v-if="pagination.totalPages > 1 && !loading"
      :per-page="pagination.limit"
      :total="pagination.totalResults"
      :total-pages="pagination.totalPages"
      :current.sync="pagination.page"
      @page-changed="$emit('page-changed', $event)"
    />
  </div>
</template>

<script>
import CardActivityLog from '@/fw-modules/fw-core-vue/activity/components/cards/CardActivityLog'
import ContentLoader from '@/fw-modules/fw-core-vue/ui/components/animation/ContentLoader'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'

export default {
  components: {
    ContentLoader,
    CardActivityLog,
    BlockPagination,
  },

  props: {
    activity: {
      type: Array,
      default: () => [],
    },
    users: {
      type: Object,
      default: () => {},
    },
    userAgents: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Object,
      default: () => {
        return {
          page: 1,
          totalResults: 0,
          totalPages: 1,
          limit: 25,
        }
      },
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "noActivity": "Sem auditoria"
  },
  "en": {
    "noActivity": "No activity"
  }
}
</i18n>
