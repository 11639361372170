<template>
  <fw-panel title="Metadados do espaço" boxed="sm">
    <div class="flex gap-10">
      <div class="flex flex-col gap-1">
        <div>
          <fw-label marginless>{{ $t('prefix.label') }}</fw-label>
        </div>
        <div class="font-bold flex-shrink-0 text-gray-500 py-2">{{ space.prefix | uppercase }}</div>
      </div>
      <div class="flex-1 flex flex-col gap-1">
        <div>
          <fw-label marginless>{{ $t('title.label') }}</fw-label>
        </div>
        <div class="flex gap-3 max-w-lg flex-1">
          <div class="flex-1">
            <TextInput
              v-model="space.title"
              :minlength="1"
              :maxlength="250"
              :placeholder="$t('title.placeholder')"
              :class="{
                error: $v.space.title.$error,
              }"
            />
            <fw-tip v-if="$v.space.title.$error" error>
              <span v-if="!$v.space.title.required">{{ $t('title.required') }}</span>
              <span v-else>{{ $t('title.invalid') }}</span>
            </fw-tip>
          </div>
        </div>
      </div>
      <div class="flex-1 flex flex-col gap-1">
        <div>
          <fw-label marginless>{{ $t('teamName.label') }}</fw-label>
        </div>
        <div class="flex gap-3 max-w-lg flex-1">
          <div class="flex-1">
            <TextInput
              v-model="space.team_name"
              :minlength="2"
              :maxlength="50"
              :placeholder="$t('teamName.placeholder')"
              :class="{
                error: $v.space.team_name.$error,
              }"
            >
            </TextInput>
            <fw-tip v-if="$v.space.team_name.$error" error>
              <span v-if="!$v.space.team_name.required">{{ $t('teamName.required') }}</span>
              <span v-else>{{ $t('teamName.invalid') }}</span>
            </fw-tip>
          </div>
        </div>
      </div>
      <div v-if="canEdit" class="pt-1 self-end">
        <fw-button type="primary" @click.native="saveSpace">{{ $t('save') }}</fw-button>
      </div>
    </div>
    <div v-if="$v.$error" class="p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium">
      <fw-icon-error-warning class="w-6 h-6"></fw-icon-error-warning>
      <span class="hidden lg:block">{{ $t('thereAreErrors') }}</span>
    </div>
  </fw-panel>
</template>

<script>
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import { required, maxLength, minLength } from 'vuelidate/lib/validators'

export default {
  components: {
    TextInput,
  },

  props: {
    canEdit: {
      type: Boolean,
      default: false,
    },
    savingData: {
      type: Boolean,
      default: false,
    },
    startAsEditing: {
      type: Boolean,
      default: false,
    },
    featured: {
      type: Boolean,
      default: false,
    },
    sticky: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    space: {
      type: Object,
      default: () => {
        return {
          title: null,
          prefix: null,
          type: 'support',
          team_name: null,
        }
      },
    },
  },

  data() {
    return {}
  },

  validations: {
    space: {
      prefix: { required, min: minLength(1), max: maxLength(10) },
      title: { required, min: minLength(2), max: maxLength(250) },
      type: { required },
      team_name: { required, min: minLength(2), max: maxLength(50) },
    },
  },

  methods: {
    saveSpace() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.$emit('save', this.space)
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "charsLimit": "Limite de {limit} caracteres.",
    "thereAreErrors": "Existem erros de formulário",
    "save": "Guardar",
    "close": "Fechar",
    "edit": "Editar",
    "title": {
      "label": "Título",
      "placeholder": "Título",
      "required": "Insira um título",
      "invalid": "Insira um título válido"
    },
    "prefix": {
      "label": "Prefixo"
    },
    "teamName": {
      "label": "Nome da equipa",
      "placeholder": "Nome da equipa",
      "required": "Insira o nome da equipa",
      "invalid": "Insira um nome da equipa válido"
    }
  },
  "en": {
    "close": "Close",
    "edit": "Edit",
    "charsLimit": "Limit of {limit} characters.",
    "thereAreErrors": "There are errors in the form",
    "save": "Save",
    "title": {
      "label": "Title",
      "placeholder": "Title",
      "required": "Enter a title",
      "invalid": "Enter a valid title"
    },
    "prefix": {
      "label": "Prefix"
    },
    "teamName": {
      "label": "Team Name",
      "placeholder": "Team Name",
      "required": "Enter a team name",
      "invalid": "Enter a valid team name"
    }
  }
}
</i18n>
