<template>
  <div class="w-full h-full flex">
    <div class="h-full w-80 lg:w-96 py-2 flex gap-3 flex-col">
      <div class="flex gap-2 justify-between items-center px-4">
        <div>
          <fw-heading muted :counter="tasks.length" :counter-total="pagination.totalResults">
            {{ $t('tasks') }}
          </fw-heading>
        </div>
        <div>
          <Filters
            :filters="filters"
            :selected-filter.sync="appliedFilter"
            :show-filter-label="false"
            @filter-changed="filterChanged"
          />
        </div>
      </div>
      <div class="px-4">
        <ContextualSearch type="minimal" :loading="loading" :show-filters="false" @search="search" />
      </div>
      <div class="flex-1 overflow-auto pt-2 px-4">
        <LoadingPlaceholder v-if="loading" />
        <RecycleScroller
          v-else-if="tasks && tasks.length"
          v-slot="{ item }"
          :items="tasks"
          :item-size="108"
          :buffer="25"
          key-field="key"
        >
          <RecordTask
            :task="item"
            :user="users[item.user_key]"
            type="minimal"
            class="cursor-pointer"
            :active="taskId === item.id"
            @open="openTask($event)"
          />
        </RecycleScroller>
        <fw-panel-info v-else centered>{{ $t('noTasks') }}</fw-panel-info>
      </div>
      <div v-if="pagination.totalPages > 1 && !loading" class="px-4">
        <BlockPagination
          :per-page="pagination.limit"
          :total="pagination.totalResults"
          :total-pages="pagination.totalPages"
          :current.sync="pagination.page"
          :range-before="1"
          :range-after="1"
          @page-changed="$emit('page-changed', $event)"
        />
      </div>
    </div>
    <div class="flex-1 h-full border-l border-gray-200">
      <PanelTask
        v-if="taskId"
        :key="taskId"
        :task-id="taskId"
        :space="space"
        :space-key="spaceKey"
        :queue-key="queueKey"
        :queue-prefix="queuePrefix"
        @task-updated="$emit('task-updated', $event)"
      />
    </div>
  </div>
</template>

<script>
import Filters from '@/fw-modules/fw-core-vue/ui/components/search/Filters'
import RecordTask from '@/components/records/RecordTask'
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
import PanelTask from '@/components/panels/PanelTask'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import { TASK_STATUS_TAG } from '@/utils/index.js'
import { RecycleScroller } from 'vue-virtual-scroller'

export default {
  components: {
    BlockPagination,
    RecycleScroller,
    Filters,
    PanelTask,
    LoadingPlaceholder,
    ContextualSearch,
    RecordTask,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    view: {
      type: String,
      default: '',
    },
    startingFilter: {
      type: String,
      default: null,
    },
    space: {
      type: Object,
      default: () => {},
    },
    tasks: {
      type: Array,
      default: () => [],
    },
    users: {
      type: Object,
      default: () => {},
    },
    pagination: {
      type: Object,
      default: () => {
        return {
          page: 1,
          totalResults: 0,
          totalPages: 1,
          limit: 25,
        }
      },
    },
  },

  data() {
    return {
      tagType: TASK_STATUS_TAG,
      filters: [
        {
          key: 'status',
          label: this.$t('status.label'),
          options: [
            {
              key: 'all',
              label: this.$t('all'),
            },
            {
              key: 'new',
              label: this.$t('status.new'),
            },
            {
              key: 'open',
              label: this.$t('status.open'),
            },
            {
              key: 'new-open',
              label: this.$t('status.newOpen'),
            },
            // {
            //   key: 'canceled',
            //   label: this.$t('status.canceled'),
            // },
            // {
            //   key: 'blocked',
            //   label: this.$t('status.blocked'),
            // },
            {
              key: 'closed',
              label: this.$t('status.closed'),
            },
            {
              key: 'stale',
              label: this.$t('stale'),
            },
          ],
        },
      ],
      appliedFilter: {
        key: 'new-open',
        label: this.$t('newOpen'),
      },
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    spaceKey() {
      return this.$route.params.key
    },

    queueKey() {
      return this.$route.params.queueKey
    },

    taskId() {
      return this.$route.params.taskId
    },

    isMobile() {
      return utils.isMobile()
    },

    queuePrefix() {
      return this.space?.queues?.find(el => el.key == this.queueKey)?.prefix ?? ''
    },
  },

  watch: {
    queueKey(newVal) {
      if (newVal)
        this.filterChanged({
          key: 'new-open',
          label: this.$t('newOpen'),
        })
    },
  },

  mounted() {
    if (this.startingFilter) this.appliedFilter = this.filters[0].options.find(el => el.key == this.startingFilter)
  },

  methods: {
    filterChanged(filter) {
      this.appliedFilter = filter
      this.$emit('get-tasks', filter.key)
    },

    search({ term } = {}) {
      this.$emit('get-tasks', null, term)
    },

    openTask(taskId) {
      this.$router.push({
        name: 'manage-space-queue-task',
        params: { key: this.spaceKey, queueKey: this.queueKey, taskId: taskId },
      })
    },

    goback() {
      this.$router.push({ name: `queue-tasks`, params: { key: this.spaceKey, queueKey: this.queueKey } })
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "tasks": "Tarefas",
    "noTasks": "Sem tarefas para apresentar",
    "all": "Todas",
    "stale": "Pendente",
    "status": {
      "label": "Estado",
      "new": "Novas",
      "open": "Abertas",
      "newOpen": "Novas e abertas",
      "canceled": "Canceladas",
      "blocked": "Bloqueadas",
      "closed": "Fechadas",
      "deleted": "Apagadas",
      "archived": "Arquivadas"
    }
  },
  "en": {
    "tasks": "Tasks",
    "noTasks": "No tasks to show",
    "all": "All",
    "stale": "Stale",
    "status": {
      "label": "Status",
      "new": "New",
      "open": "Open",
      "canceled": "Canceled",
      "blocked": "Blocked",
      "closed": "Closed",
      "deleted": "Deleted",
      "archived": "Deprecated"
    }
  }
}
</i18n>
