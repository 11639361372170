<template>
  <div>
    <fw-heading size="xl">{{ $t('people') }}</fw-heading>

    <LoadingPlaceholder v-if="loading" />

    <fw-panel :title="$t('spaceOwner')" boxed="xs" custom-class="bg-white" class="mt-5 mb-6" counter="1">
      <template #toolbar>
        <fw-button v-if="canEditOwner" type="link" @click.native="choosePeople('spaceOwner')">{{
          $t('edit')
        }}</fw-button>
      </template>
      <div>
        <fw-person
          :open-details-on-click="canEdit"
          :person="users[space.user_key]"
          :selectable="false"
          :clickable="canEdit"
        ></fw-person>
      </div>
    </fw-panel>

    <fw-panel
      v-if="!loading"
      :title="$t('spaceManagers')"
      :boxed="spaceAdminsCount ? 'xs' : null"
      custom-class="bg-white"
      class="my-5"
      :counter="spaceAdminsCount"
    >
      <ExpandList
        v-if="spaceAdminsCount || canEdit"
        :add-title="$t('addSpaceManagers')"
        :empty="!spaceAdmins || spaceAdminsCount < 1"
        :show-add-items="canEdit"
        @add-element="choosePeople('spaceAdmins', null)"
      >
        <template #list>
          <fw-person
            v-for="(spaceKey, userKey, index) in spaceAdmins"
            :key="userKey"
            :class="{
              'border-b border-gray-100': index < spaceAdminsCount - 1,
            }"
            :open-details-on-click="canEdit"
            :person="users[userKey]"
            :selectable="false"
            :clickable="canEdit"
          >
            <template v-if="(userKey != loggedUser.key || loggedUser.key == space.user_key) && canEdit" #options>
              <b-dropdown aria-role="list" position="is-bottom-left">
                <fw-button-dropdown slot="trigger" :chevron="false" type="simple" class="flex flex-col" label="Ações">
                  <fw-icon-more class="w-5 h-5" />
                </fw-button-dropdown>
                <b-dropdown-item custom aria-role="menuitem" class="paddingless">
                  <fw-button
                    type="basic-action"
                    custom-class="w-full"
                    @click.native="deleteManager(userKey, 'space-admin')"
                  >
                    {{ $t('delete') }}</fw-button
                  >
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </fw-person>
        </template>
      </ExpandList>
      <fw-panel-info v-else empty left>Não foram associados utilizadores.</fw-panel-info>
    </fw-panel>

    <fw-panel
      v-if="!loading"
      :title="$t('queueManagers')"
      :boxed="queuesAdminsCount ? 'xs' : null"
      custom-class="bg-white"
      class="my-5"
      :counter="queuesAdminsCount"
    >
      <ExpandList
        v-if="queuesAdminsCount || canEdit"
        :add-title="$t('addQueueManagers')"
        :empty="!queuesAdmins || queuesAdminsCount < 1"
        :show-add-items="canEdit"
        @add-element="choosePeople('queuesAdmins')"
      >
        <template #list>
          <fw-person
            v-for="(queueKeys, userKey, index) in queuesAdmins"
            :key="userKey"
            :class="{
              'border-b border-gray-100': index < queuesAdminsCount - 1,
            }"
            :clickable="canEdit"
            :person="users[userKey]"
            :selectable="false"
            @clicked="
              openPersonDetails({ ...users[userKey], queueKeys: queueKeys, role: 'queue-admin' }, 'queuesAdmins')
            "
          >
            <template #secondline>
              <div v-if="users[userKey]" class="text-sm text-gray-500 font-normal flex gap-2 items-center">
                {{ users[userKey].email }}
              </div>
              <div class="text-sm text-gray-500 font-normal flex gap-2 items-center pt-2">
                <template v-for="queue in queuesAdminsSortedQueues[userKey]">
                  <fw-tag :key="queue.key" type="light-border-box" size="xs">{{ queue.prefix | uppercase }}</fw-tag>
                </template>
              </div>
            </template>
            <template
              v-if="(userKey != loggedUser.key || userKey in spaceAdmins || userKey == space.user_key) && canEdit"
              #options
            >
              <b-dropdown aria-role="list" position="is-bottom-left">
                <fw-button-dropdown slot="trigger" :chevron="false" type="simple" class="flex flex-col" label="Ações">
                  <fw-icon-more class="w-5 h-5" />
                </fw-button-dropdown>
                <b-dropdown-item custom aria-role="menuitem" class="paddingless">
                  <fw-button
                    type="basic-action"
                    custom-class="w-full"
                    @click.native="deleteManager(userKey, 'queue-admin')"
                  >
                    {{ $t('delete') }}</fw-button
                  >
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </fw-person>
        </template>
      </ExpandList>
      <fw-panel-info v-else empty left>Não foram associados utilizadores.</fw-panel-info>
    </fw-panel>

    <fw-panel
      v-if="!loading"
      :title="$t('queueWorkers')"
      :boxed="queuesWorkersCount ? 'xs' : null"
      custom-class="bg-white"
      class="my-5"
      :counter="queuesWorkersCount"
    >
      <ExpandList
        v-if="queuesWorkersCount || canEdit"
        :add-title="$t('addQueueWorkers')"
        :empty="!queuesWorkers || queuesWorkersCount < 1"
        :show-add-items="canEdit"
        @add-element="choosePeople('queuesWorkers')"
      >
        <template #list>
          <fw-person
            v-for="(queueKeys, userKey, index) in queuesWorkers"
            :key="userKey"
            :class="{
              'border-b border-gray-100': index < queuesWorkersCount - 1,
            }"
            :clickable="canEdit"
            :person="users[userKey]"
            :selectable="false"
            @clicked="
              openPersonDetails({ ...users[userKey], queueKeys: queueKeys, role: 'queue-worker' }, 'queuesWorkers')
            "
          >
            <template #secondline>
              <div v-if="users[userKey]" class="text-sm text-gray-500 font-normal flex gap-2 items-center">
                {{ users[userKey].email }}
              </div>
              <div class="text-sm text-gray-500 font-normal flex gap-2 items-center pt-2">
                <template v-for="queue in queuesWorkersSortedQueues[userKey]">
                  <fw-tag :key="queue.key" type="light-border-box" size="xs">{{ queue.prefix | uppercase }}</fw-tag>
                </template>
              </div>
            </template>
            <template
              v-if="
                (userKey != loggedUser.key ||
                  userKey in queuesAdmins ||
                  userKey in spaceAdmins ||
                  userKey == space.user_key) &&
                  canEdit
              "
              #options
            >
              <b-dropdown aria-role="list" position="is-bottom-left">
                <fw-button-dropdown slot="trigger" :chevron="false" type="simple" class="flex flex-col" label="Ações">
                  <fw-icon-more class="w-5 h-5" />
                </fw-button-dropdown>
                <b-dropdown-item custom aria-role="menuitem" class="paddingless">
                  <fw-button
                    type="basic-action"
                    custom-class="w-full"
                    @click.native="deleteManager(userKey, 'queue-worker')"
                  >
                    {{ $t('delete') }}</fw-button
                  >
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </fw-person>
        </template>
      </ExpandList>
      <fw-panel-info v-else empty left>Não foram associados utilizadores.</fw-panel-info>
    </fw-panel>

    <fw-panel-info debug label="People (raw)">
      <json-viewer :value="{ spaceAdmins, queuesAdmins, queuesWorkers, canEdit }"></json-viewer>
    </fw-panel-info>

    <fw-modal
      :active.sync="activeChoosingPeopleModal"
      :can-cancel="true"
      boxed="xs"
      size="min"
      width="42rem"
      @close="closeModal"
    >
      <ModalChooseSpaceOwner
        v-if="choosingPeopleType == 'spaceOwner'"
        :not-alowed-users="excludeUsers.concat([space.user_key])"
        :invite-people="false"
        :current-owner="users[space.user_key]"
        :space="space"
        :endpoint="spaceManagersSearch"
        @selected="selectedSpaceOwner"
        @close="closeModal"
      ></ModalChooseSpaceOwner>
      <ChoosePeopleModal
        v-else-if="choosingPeopleType == 'spaceAdmins'"
        :title="selectPeopleTitle"
        :instructions="selectPeopleInstructions"
        :multiselect="true"
        :not-alowed-users="excludeUsers"
        :invite-people="false"
        :endpoint="spaceManagersSearch"
        @selected="selectedSpaceManagers"
        @close="closeModal"
      ></ChoosePeopleModal>
      <ModalChooseQueuesManagersModal
        v-else
        :title="selectPeopleTitle"
        :instructions="selectPeopleInstructions"
        :multiselect="true"
        :endpoint="spaceManagersSearch"
        :not-alowed-users="excludeUsers"
        :queues="space.queues"
        :space-key="space.key"
        @selected="selectedQueuesManagers"
        @close="closeModal"
      ></ModalChooseQueuesManagersModal>
    </fw-modal>

    <fw-modal
      :active.sync="activeUserDetailsModal"
      :can-cancel="true"
      boxed="xs"
      size="min"
      width="42rem"
      @close="closePersonDetails"
    >
      <UserDetails
        v-if="activeUserDetailsModal"
        :user="selectedUser"
        :saving-data="savingData"
        :queues="space.queues"
        @update-queues="
          activeUserDetailsRefresh = true
          selectedQueuesManagers($event)
        "
        @close="closePersonDetails"
      />
    </fw-modal>
  </div>
</template>

<script>
import ExpandList from '@/fw-modules/fw-core-vue/ui/components/lists/ExpandList'
import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal'
import ModalChooseSpaceOwner from '@/components/modals/ModalChooseSpaceOwner'
import ModalChooseQueuesManagersModal from '@/components/modals/ModalChooseQueuesManagers'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import UserDetails from '@/components/modals/UserDetails'

import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    ExpandList,
    ChoosePeopleModal,
    ModalChooseQueuesManagersModal,
    ModalChooseSpaceOwner,
    LoadingPlaceholder,
    UserDetails,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    savingData: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canEditOwner: {
      type: Boolean,
      default: false,
    },
    space: {
      type: Object,
      default: () => {},
    },
    users: {
      type: Object,
      default: () => {},
    },
    usersRoles: {
      type: Object,
      default: () => {
        return {
          space: {
            'space-admin': {},
          },
          queues: {
            'queue-admin': {},
            'queue-worker': {},
          },
        }
      },
    },
  },

  data() {
    return {
      activeUserDetailsModal: false,
      activeUserDetailsRefresh: false,
      activeChoosingPeopleModal: false,
      selectedUser: {},
      choosingPeopleType: null,
      choosingPeopleQueue: null,
      choosingPeopleTitles: {
        spaceOwner: {
          title: this.$t('selectSpaceOwner'),
          instructions: this.$t('chooseSpaceOwner'),
        },
        spaceAdmins: {
          title: this.$t('selectSpaceManager'),
          instructions: this.$t('chooseSpaceManager'),
        },
        queuesAdmins: {
          title: this.$t('selectQueueManager'),
          instructions: this.$t('chooseQueueManager'),
        },
        queuesWorkers: {
          title: this.$t('selectQueueWorkers'),
          instructions: this.$t('chooseQueueWorkers'),
        },
      },
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    loggedUser() {
      return this.$store.getters.getUser
    },

    queuesByKey() {
      if (!this.space.queues || !this.space.queues.length) return {}
      const queuesByKey = {}
      this.space.queues.forEach(queue => {
        queuesByKey[queue.key] = queue
      })

      return queuesByKey
    },

    selectPeopleTitle() {
      return this.choosingPeopleType ? this.choosingPeopleTitles[this.choosingPeopleType].title : ''
    },

    selectPeopleInstructions() {
      return this.choosingPeopleType ? this.choosingPeopleTitles[this.choosingPeopleType].instructions : ''
    },

    excludeUsers() {
      console.log('choosingPeopleType :>> ', this.choosingPeopleType)
      if (this.choosingPeopleType == 'spaceAdmins') {
        console.log('Exclude users spaceAdmins :>> ', this.spaceAdmins)
        return Object.keys(this.spaceAdmins)
      }

      if (this.choosingPeopleType == 'queuesAdmins') {
        console.log('Exclude users queuesAdmins, spaceAdmins :>> ', this.queuesAdmins, this.spaceAdmins)
        return Object.keys(this.queuesAdmins).concat(Object.keys(this.spaceAdmins))
      }

      console.log('Exclude users queuesWorkers :>> ', this.queuesWorkers)
      return Object.keys(this.queuesWorkers)
    },

    spaceAdmins() {
      return this.usersRoles && this.usersRoles.space ? this.usersRoles.space['space-admin'] : {}
    },

    spaceAdminsCount() {
      return Object.keys(this.spaceAdmins).length
    },

    queuesAdmins() {
      return this.usersRoles && this.usersRoles.queues ? this.usersRoles.queues['queue-admin'] : {}
    },

    queuesAdminsCount() {
      return Object.keys(this.queuesAdmins).length
    },

    queuesAdminsSortedQueues() {
      if (utils.isObjectEmpty(this.queuesAdmins) || utils.isObjectEmpty(this.queuesByKey)) return {}

      const workersSortedQueues = {}
      for (const [userKey, queueKeys] of Object.entries(this.queuesAdmins)) {
        workersSortedQueues[userKey] = queueKeys
          .map(key => this.queuesByKey[key])
          .sort((a, b) => a.prefix.localeCompare(b.prefix))
      }

      return workersSortedQueues
    },

    queuesWorkers() {
      return this.usersRoles && this.usersRoles.queues ? this.usersRoles.queues['queue-worker'] : {}
    },

    queuesWorkersCount() {
      return Object.keys(this.queuesWorkers).length
    },

    queuesWorkersSortedQueues() {
      if (utils.isObjectEmpty(this.queuesWorkers) || utils.isObjectEmpty(this.queuesByKey)) return {}

      const workersSortedQueues = {}
      for (const [userKey, queueKeys] of Object.entries(this.queuesWorkers)) {
        workersSortedQueues[userKey] = queueKeys
          .map(key => this.queuesByKey[key])
          .sort((a, b) => a.prefix.localeCompare(b.prefix))
      }

      return workersSortedQueues
    },
  },

  methods: {
    async spaceManagersSearch(params) {
      return await this.api.spaceUsersSearch(this.space.key, params)
    },

    choosePeople(type) {
      console.log('choosePeople :>> ', type)
      this.choosingPeopleType = type
      this.activeChoosingPeopleModal = true
    },

    openPersonDetails(user, roleType) {
      console.log('openPersonDetails :>> ', user, roleType)
      this.selectedUser = user
      this.activeUserDetailsRefresh = false
      this.activeUserDetailsModal = true
      this.activeChoosingPeopleModal = false
      this.choosingPeopleType = roleType
    },

    closePersonDetails() {
      console.log('closePersonDetails')
      this.activeUserDetailsModal = false
      this.selectedUser = {}
      if (this.activeUserDetailsRefresh) {
        this.$emit('refresh-data')
      }
      this.choosingPeopleType = null
      this.activeUserDetailsRefresh = false
    },

    selectedSpaceOwner(user) {
      // Payload necessary: {"space": { userKey: [role] } }
      console.log('selectedSpaceOwner :>> ', user)

      this.$emit('update-managers', {
        type: this.choosingPeopleType,
        usersAndRoles: { owner: user.key },
      })
    },

    selectedSpaceManagers(selection) {
      // Payload necessary: {"space": { userKey: [role] } }
      console.log('selectedSpaceManagers :>> ', selection)
      const managers = {}
      selection.forEach(user => {
        managers[user.key] = ['space-admin']
      })
      this.$emit('update-managers', {
        type: this.choosingPeopleType,
        usersAndRoles: { space: managers },
        refresh: true,
      })
    },

    selectedQueuesManagers({ users, queues: selectedQueues, refresh }) {
      console.log('selectedQueuesManagers :>> ', { users, queues: selectedQueues, refresh })
      const managers = {}
      const managersToDel = {}

      this.space.queues.forEach(queue => {
        const queueKey = queue.key
        managers[queueKey] = {}
        users.forEach(userKey => {
          const roles = []
          if (this.choosingPeopleType == 'queuesAdmins') {
            if (userKey in this.queuesWorkers && this.queuesWorkers[userKey].includes(queueKey)) {
              roles.push('queue-worker')
            }
            if (selectedQueues.includes(queueKey)) roles.push('queue-admin')
          } else if (this.choosingPeopleType == 'queuesWorkers') {
            if (userKey in this.queuesAdmins && this.queuesAdmins[userKey].includes(queueKey)) {
              roles.push('queue-admin')
            }
            if (selectedQueues.includes(queueKey)) roles.push('queue-worker')
          }

          if (queueKey in managers[queueKey]) {
            managers[queueKey][userKey] = managers[queueKey][userKey].concat(roles)
          } else {
            managers[queueKey][userKey] = roles
          }

          if (roles.length < 1) {
            // Deletes all roles for this user in this queue
            managersToDel[queueKey] = { [userKey]: [] }
          }
        })
      })

      if (!utils.isObjectEmpty(managersToDel)) {
        this.$emit('delete-managers', {
          type: 'queueWorkers',
          usersAndRoles: { queues: managersToDel },
          refresh: false,
        })
      }

      if (!utils.isObjectEmpty(managers)) {
        this.$emit('update-managers', {
          type: this.choosingPeopleType,
          usersAndRoles: { queues: managers },
          refresh: refresh,
        })
      }

      this.closeModal()
    },

    removeFromQueueWorker(userKey) {
      console.log('removeFromQueueWorker :>> ', userKey)
      const userRoles = {}
      if (userKey in this.queuesAdmins) {
        this.queuesAdmins[userKey].forEach(queueKey => {
          userRoles[queueKey] = { [userKey]: ['queue-admin'] }
        })

        this.$emit('update-managers', {
          type: 'queueWorkers',
          usersAndRoles: { queues: userRoles },
          refresh: true,
        })

        return {
          type: 'queueWorkers',
          usersAndRoles: { queues: userRoles },
          refresh: true,
        }
      } else {
        this.queuesWorkers[userKey].forEach(queueKey => {
          userRoles[queueKey] = { [userKey]: [] }
        })

        this.$emit('delete-managers', {
          type: 'queueWorkers',
          usersAndRoles: { queues: userRoles },
          refresh: true,
        })

        return {
          type: 'queueWorkers',
          usersAndRoles: { queues: userRoles },
          refresh: true,
        }
      }
    },

    removeFromQueueAdmin(userKey) {
      console.log('removeFromQueueAdmin :>> ', userKey)
      const userRoles = {}
      if (userKey in this.queuesWorkers) {
        this.queuesWorkers[userKey].forEach(queueKey => {
          userRoles[queueKey] = { [userKey]: ['queue-worker'] }
        })

        this.$emit('update-managers', {
          type: 'queueWorkers',
          usersAndRoles: { queues: userRoles },
          refresh: true,
        })

        return {
          type: 'queueWorkers',
          usersAndRoles: { queues: userRoles },
          refresh: true,
        }
      } else {
        this.queuesAdmins[userKey].forEach(queueKey => {
          userRoles[queueKey] = { [userKey]: [] }
        })

        this.$emit('delete-managers', {
          type: 'queueWorkers',
          usersAndRoles: { queues: userRoles },
          refresh: true,
        })

        return {
          type: 'queueWorkers',
          usersAndRoles: { queues: userRoles },
          refresh: true,
        }
      }
    },

    removeFromSpaceAdmin(userKey) {
      if (userKey in this.spaceAdmins) {
        this.$emit('delete-managers', {
          type: 'spaceAdmins',
          usersAndRoles: { space: { [userKey]: [] } },
          refresh: true,
        })
      }
    },

    deleteManager(userKey, role) {
      console.log('deleteManager userKey, role :>> ', userKey, role)
      if (role == 'space-admin') {
        this.removeFromSpaceAdmin(userKey)
      } else if (role == 'queue-worker') {
        this.removeFromQueueWorker(userKey)
      } else if (role == 'queue-admin') {
        this.removeFromQueueAdmin(userKey)
      }
    },

    closeModal() {
      this.choosingPeopleType = null
      this.activeChoosingPeopleModal = false
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "people": "Pessoas",
    "spaceOwner": "Responsável do espaço",
    "edit": "Alterar",
    "spaceManagers": "Gestor(es) do espaço",
    "addSpaceManagers": "Adicionar gestor(es) do espaço",
    "delete": "Remover",
    "queueManagers": "Gestor(es) de fila",
    "addQueueManagers": "Adicionar gestor(es) da fila",
    "queueWorkers": "Trabalhador(es) de fila",
    "addQueueWorkers": "Adicionar trabalhador(es) de fila",
    "chooseSpaceOwner": "Escolha o responsável do espaço",
    "chooseSpaceManager": "Escolha o(s) gestor(es) do espaço",
    "chooseQueueManager": "Escolha o(s) gestor(es) da fila",
    "chooseQueueWorkers": "Escolha o(s) trabalhador(es) da fila",
    "selectSpaceOwner": "Selecione o utilizador que quer como responsável do espaço:",
    "selectSpaceManager": "Selecione o(s) utilizador(es) que quer como gestor(es) do espaço:",
    "selectQueueManager": "Selecione o(s) utilizador(es) que quer como gestor(es) da fila:",
    "selectQueueWorkers": "Selecione o(s) utilizador(es) que quer como trabalhador(es) da fila:",
    "isRoleIn": "É <b>{role}</b> nas filas:",
    "roles": {
      "space-admin": "gestor de espaço",
      "queue-admin": "gestor de fila",
      "queue-worker": "trabalhador de fila"
    }
  },
  "en": {
    "people": "People",
    "edit": "Edit",
    "spaceManagers": "Space manager(s)",
    "spaceOwner": "Head of space",
    "addSpaceManagers": "Add space manager(s)",
    "delete": "Delete",
    "queueManagers": "Queue manager(s)",
    "addQueueManagers": "Add queue manager(s)",
    "queueWorkers": "Queue worker(s)",
    "addQueueWorkers": "Add queue worker(s)",
    "chooseSpaceOwner": "Choose the head of the space",
    "chooseSpaceManager": "Choose the space manager(s)",
    "chooseQueueManager": "Choose the queue manager(s)",
    "chooseQueueWorkers": "Choose the queue worker(s)",
    "selectSpaceManager": "Select the user(s) you want as space manager:",
    "selectSpaceOwner": "Select the user you want as head of space:",
    "selectQueueManager": "Select the user(s) you want as queue manager:",
    "selectQueueWorkers": "Select the user(s) you want as queue worker:",
    "isRoleIn": "Is <b>{role}</b> in queues:",
    "roles": {
      "space-admin": "space manager",
      "queue-admin": "queue manager",
      "queue-worker": "tasks assignees"
    }
  }
}
</i18n>
