<template>
  <div>
    <LoadingPlaceholder v-if="loading" />
    <div v-else-if="!loading && space && space.key">
      <DashboardHero :title="space.title" :tag="$t(`spaceType.${space.type}`)" class="mt-2 mb-5" type="cover" size="xs">
        <template #title>
          <fw-heading size="h1">
            {{ space.title }}
          </fw-heading>
        </template>

        <template #footer>
          <div class="text-center">
            <fw-label color="text-white opacity-50">{{ $t('prefix') }}</fw-label>
            <div v-if="space.prefix">{{ space.prefix | uppercase }}</div>
            <div v-else>{{ $t('notDefined') }}</div>
          </div>
          <div>
            <fw-label color="text-white opacity-50">{{ $t('createdAt') }}</fw-label>
            <div>{{ space.created_at | formatDateTime }}</div>
          </div>
        </template>
      </DashboardHero>

      <fw-panel :title="$t('queues')" class="my-5">
        <div
          v-if="space.queues && space.queues.length"
          class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-4 mb-5"
        >
          <fw-card
            v-for="queue in space.queues"
            :key="queue.key"
            :title="queue.title"
            icon="cube"
            :label="queue?.prefix.toUpperCase()"
            @click.native="goToQueue(queue.key)"
          >
            <template #default>
              <div class="flex flex-col gap-2 h-full">
                <div>
                  <fw-tag
                    expanded
                    align="left"
                    :type="queuesStats[queue.key]?.new ? 'primary' : 'xlight'"
                    :counter="queuesStats[queue.key]?.new ?? '0'"
                    >Novas</fw-tag
                  >
                </div>
                <div>
                  <fw-tag
                    expanded
                    align="left"
                    :type="queuesStats[queue.key]?.new ? 'light-primary' : 'xlight'"
                    :counter="queuesStats[queue.key]?.open ?? '0'"
                    >Abertas</fw-tag
                  >
                </div>
                <div>
                  <fw-tag expanded align="left" type="xlight" :counter="queuesStats[queue.key]?.closed ?? '0'"
                    >Fechadas</fw-tag
                  >
                </div>
              </div>
            </template>
          </fw-card>
        </div>
        <fw-panel-info v-else centered empty>{{ $t('noQueues') }}.</fw-panel-info>
      </fw-panel>
    </div>

    <fw-panel-info debug label="Queues (raw)">
      <json-viewer :value="{ space, queuesStats }"></json-viewer>
    </fw-panel-info>
  </div>
</template>

<script>
import DashboardHero from '@/fw-modules/fw-core-vue/ui/components/dashboard/DashboardHero'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'

export default {
  components: {
    DashboardHero,
    LoadingPlaceholder,
  },

  props: {
    space: {
      type: Object,
      default: () => {},
    },
    users: {
      type: Object,
      default: () => {},
    },
    queuesStats: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    spaceKey() {
      return this.$route.params.key
    },

    usersKeys() {
      let users = this.space.managers ? new Set(Object.keys(this.space.managers)) : new Set()
      this.space.queues?.forEach(queue => {
        if (queue.managers) users = new Set([...users, ...Object.keys(queue.managers)])
      })

      return Array.from(users)
    },
  },

  methods: {
    goToQueue(queueKey) {
      return this.$router.push({ name: 'manage-space-queue', params: { key: this.spaceKey, queueKey: queueKey } })
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "createdAt": "Criado em",
    "creator": "Criado por",
    "notDefined": "Não definido",
    "noQueues": "Sem filas",
    "numTasks": "Número de tasks",
    "title": "Titulo",
    "prefix": "Prefixo",
    "queues": "Filas",
    "noUsers": "Sem gestores ou trabalhador associados",
    "spaceType": {
      "support": "Suporte",
      "development": "Desenvolvimento"
    }
  },
  "en": {
    "creator": "Created by",
    "createdAt": "Created at",
    "notDefined": "Not defined",
    "noQueues": "No queues",
    "numTasks": "Number of tasks",
    "prefix": "Prefix",
    "title": "Title",
    "queues": "Queues",
    "noUsers": "No associated Managers or Workers",
    "spaceType": {
      "support": "Support",
      "development": "Development"
    }
  }
}
</i18n>
