<template>
  <fw-panel featured :title="$t('queues')">
    <ContextualSearch
      v-if="showSearch"
      :loading="loading"
      :filter-options="filters"
      :applied-filters="appliedFilters"
      :applied-sort="orderByValue"
      :applied-sort-direction="orderDirection"
      :multifilter="multifilter"
      :start-value="searchInput"
      :start-period="startPeriod"
      :end-period="endPeriod"
      :order-by-options="orderBy"
      :show-time-period="true"
      :time-period-label="$t('orderBy.createdDate')"
      :can-close="activeModal === null"
      @sort-order-changed="sortOrderChanged"
      @search="search"
    >
      <template #tags>
        <FilterTag
          v-for="(filter, f) in appliedFilters"
          :key="'filter_' + f"
          :text="getFilterText(filter)"
          :show-close-button="true"
          @close="deleteFilter(f)"
        ></FilterTag>
        <FilterTag
          v-if="startPeriod != null && endPeriod != null"
          :text="startPeriod + ' - ' + endPeriod"
          :show-close-button="true"
          @close="deleteDates()"
        ></FilterTag>
      </template>
    </ContextualSearch>

    <slot name="stats" />

    <fw-panel
      :title="$t('results')"
      :counter="queues ? queues.length : 0"
      :counter-total="totalResults"
      boxed
      class="my-5"
      custom-class="bg-white"
    >
      <fw-panel-info v-if="!loading && (!queues || !queues.length)" type="basic" class="text-center my-5 text-gray-500">
        {{ $t('noQueues') }}
      </fw-panel-info>

      <RecycleScroller
        v-else-if="!loading"
        v-slot="{ item }"
        key-field="key"
        :items="queues"
        :item-size="50"
        :buffer="50"
      >
        <div class="p-1 border-b border-gray-100">
          <RecordQueue
            :queue="item"
            :user="users[item.user_key]"
            show-stats
            :stats="stats[item.key]"
            @open="openQueue(item.key, $event)"
          />
        </div>
      </RecycleScroller>

      <LoadingPlaceholder v-if="loading" />
    </fw-panel>

    <BlockPagination
      v-if="totalPages > 1 && !loading"
      :per-page="limit"
      :total="totalResults"
      :total-pages="totalPages"
      :current.sync="page"
      @page-changed="pageChanged"
    />

    <fw-panel-info debug label="Queues (raw)">
      <json-viewer :value="{ queues }"></json-viewer>
    </fw-panel-info>
  </fw-panel>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller'
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
import FilterTag from '@/fw-modules/fw-core-vue/ui/components/text/FilterTag.vue'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import RecordQueue from '@/components/records/RecordQueue'

import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  components: {
    BlockPagination,
    RecordQueue,
    RecycleScroller,
    LoadingPlaceholder,
    ContextualSearch,
    FilterTag,
  },

  props: {
    showFilters: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    multifilter: {
      type: Boolean,
      default: true,
    },
    maxNumberQueues: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      filters: [],
      orderBy: [
        {
          key: 'prefix',
          label: this.$t('orderBy.prefix'),
          type: 'string',
        },
        {
          key: 'title',
          label: this.$t('orderBy.title'),
          type: 'string',
        },
        {
          key: 'created_at',
          label: this.$t('orderBy.createdDate'),
          type: 'date',
        },
      ],
      page: 1,
      totalResults: 0,
      totalPages: 1,
      limit: 25,
      queues: [],
      stats: {},
      users: {},
      loading: true,
      searchInput: '',
      orderByValue: 'created_at',
      orderDirection: 'DESC',
      appliedFilters: [],
      startPeriod: null,
      endPeriod: null,
      activeModal: null,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    spaceKey() {
      return this.$route.params.key
    },

    isMobile() {
      return utils.isMobile()
    },
  },

  mounted() {
    utils.sleep(100).then(() => {
      this.getUrlParams()
      this.getQueues()
    })
  },

  methods: {
    deleteFilter(index) {
      this.appliedFilters.splice(index, 1)
      this.setUrlParams()
      this.getQueues()
    },

    deleteDates() {
      this.startPeriod = null
      this.endPeriod = null
      this.setUrlParams()
      this.getQueues()
    },

    sortOrderChanged(newSort) {
      if (newSort != null && newSort.key != null) {
        this.orderByValue = newSort.key.key
        this.orderDirection = newSort.direction
      }
      this.setUrlParams()
      this.getQueues()
    },

    pageChanged(page) {
      console.log('pageChanged to :>> ', page)
      if (page) this.page = page
      this.setUrlParams()
      this.getQueues()
    },

    parseDatesForPayload(formatedDate) {
      if (formatedDate) {
        return Dates.from(formatedDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      }
      return formatedDate
    },

    openQueue(queueKey) {
      this.$router.push({
        name: 'manage-space-queue',
        params: { key: this.spaceKey, queueKey: queueKey },
      })
    },

    getFilterText(key) {
      return utils.getFilterText(key, this.filters)
    },

    search(data) {
      this.appliedFilters = JSON.parse(JSON.stringify(data.filters))
      this.searchInput = data.term

      if (data.orderBy != null) {
        this.orderByValue = data.orderBy
        this.orderDirection = data.orderDirection
      }

      this.startPeriod = data.dates.length == 2 ? this.$options.filters.formatDate(data.dates[0]) : null
      this.endPeriod = data.dates.length == 2 ? this.$options.filters.formatDate(data.dates[1]) : null

      this.$emit('searching')

      this.setUrlParams()
      this.getQueues()
    },

    getUrlParams() {
      if (this.$route.query.q) {
        this.searchInput = this.$route.query.q
      }

      if (this.$route.query.f) {
        this.appliedFilters = this.$route.query.f.split(',')
      }

      if (this.$route.query.s) {
        this.orderByValue = this.$route.query.s
        this.orderDirection =
          this.$route.query.o == 'ASC' || this.$route.query.o == 'DESC' ? this.$route.query.o : 'none'
      }

      if (this.$route.query.p) {
        this.page = parseInt(this.$route.query.p)
      }

      if (this.$route.query.start) {
        this.startPeriod = this.$route.query.start
      }

      if (this.$route.query.end) {
        this.endPeriod = this.$route.query.end
      }
    },

    setUrlParams() {
      let query = {}
      if (this.searchInput.length > 0) {
        query['query'] = this.searchInput
      }

      if (this.appliedFilters.length > 0) {
        query['f'] = this.appliedFilters.join(',')
      }

      if (this.orderByValue.length > 0) {
        query['s'] = this.orderByValue
        query['o'] = this.orderDirection
      }

      if (this.startPeriod != null && this.endPeriod != null) {
        query['start'] = this.startPeriod
        query['end'] = this.endPeriod
      }

      query['p'] = this.page

      this.$router.push({ path: this.$route.path, query: query })
    },

    async getQueues() {
      this.loading = true
      const maxNumber = this.maxNumberQueues != null ? this.maxNumberQueues : this.limit
      const query = {
        limit: maxNumber,
        page: this.page,
        ...utils.setFiltersQuery(this.appliedFilters),
      }

      if (this.orderByValue.length > 0) {
        query['sort'] = this.orderByValue
        query['direction'] = this.orderDirection.toLowerCase()
      }

      if (this.searchInput.length > 0) {
        query['query'] = this.searchInput
      }

      if (this.startPeriod && this.endPeriod) {
        query['created_start'] = this.parseDatesForPayload(this.startPeriod)
        query['created_end'] = this.parseDatesForPayload(this.endPeriod)
      }

      console.log('query :>> ', query)
      try {
        const result = await this.api.getQueues(this.$route.params.key, query)
        this.totalResults = result.pagination?.total_items
        this.totalPages = result.pagination?.total_pages
        this.page = result.pagination?.current_page
        this.queues = result.queues
        this.users = result.users
        this.stats = result.stats
      } catch (error) {
        console.error('getQueues Error: ', error)
      }

      this.loading = false
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "queues": "Filas",
    "results": "Resultados",
    "noQueues": "Sem filas a mostrar",
    "all": "Todos",
    "status": {
      "label": "Estado",
      "new": "Novo",
      "open": "Aberto",
      "canceled": "Cancelado",
      "blocked": "Bloqueado",
      "closed": "Fechado",
      "deleted": "Apagado",
      "archived": "Arquivado"
    },
    "orderBy": {
      "title": "Título da fila",
      "prefix": "Prefixo da fila",
      "createdDate": "Data de criação"
    }
  },
  "en": {
    "queues": "Queues",
    "results": "Results",
    "noQueues": "No queues to show",
    "all": "All",
    "status": {
      "label": "Status",
      "new": "New",
      "open": "Open",
      "canceled": "Canceled",
      "blocked": "Blocked",
      "closed": "Closed",
      "deleted": "Deleted",
      "archived": "Deprecated"
    },
    "orderBy": {
      "title": "Queue title",
      "prefix": "Queue prefix",
      "createdDate": "Creation date"
    }
  }
}
</i18n>
