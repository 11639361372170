<template>
  <div>
    <fw-panel :title="queue.key ? $t('editQueue') : $t('newQueue')" featured custom-class="mb-5 flex flex-col gap-5">
      <div class="flex flex-col">
        <fw-label>{{ $t('prefix.label') }}</fw-label>
        <div v-if="queue.key" class="font-bold flex-shrink-0 text-gray-500">
          {{ queue.prefix | uppercase }}
        </div>
        <div v-else>
          <TextInput
            v-model.trim="queue.prefix"
            :minlength="1"
            :maxlength="10"
            uppercase
            :placeholder="$t('prefix.placeholder')"
            :help="$t('charsLimit', { limit: '10' })"
            :class="{
              error: $v.queue.prefix.$error,
            }"
          >
          </TextInput>
          <fw-tip v-if="$v.queue.prefix.$error" error>
            <span v-if="!$v.queue.prefix.required">{{ $t('prefix.required') }}</span>
            <span v-else-if="!$v.queue.prefix.alphanumeric">{{ $t('prefix.alphanumeric') }}</span>
            <span v-else>{{ $t('prefix.invalid') }}</span>
          </fw-tip>
        </div>
      </div>

      <div>
        <fw-label>{{ $t('title.label') }}</fw-label>
        <TextInput
          v-model="queue.title"
          :minlength="1"
          :maxlength="250"
          :placeholder="$t('title.placeholder')"
          :class="{
            error: $v.queue.title.$error,
          }"
        >
        </TextInput>

        <fw-tip v-if="$v.queue.title.$error" error>
          <span v-if="!$v.queue.title.required">{{ $t('title.required') }}</span>
          <span v-else>{{ $t('title.invalid') }}</span>
        </fw-tip>
      </div>
    </fw-panel>
    <div class="flex-1 flex justify-end items-center mt-3">
      <fw-button type="link-muted" class="mr-4" @click.native="$emit('close')">{{ $t('cancel') }}</fw-button>
      <fw-button type="primary" @click.native="saveQueue">{{ $t('save') }}</fw-button>
    </div>
  </div>
</template>

<script>
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import { required, maxLength, minLength } from 'vuelidate/lib/validators'
import { alphanumeric } from '@/fw-modules/fw-core-vue/utilities/vuelidateHelpers'

export default {
  components: {
    TextInput,
  },

  props: {
    queue: {
      type: Object,
      default: () => {
        return {
          title: null,
          prefix: null,
          type: 'support',
        }
      },
    },
  },

  validations: {
    queue: {
      prefix: { required, min: minLength(1), max: maxLength(10), alphanumeric },
      title: { required, min: minLength(2), max: maxLength(250) },
    },
  },

  methods: {
    saveQueue() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.$emit('save', this.queue)
      this.$emit('close')
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "charsLimit": "Limite de {limit} caracteres.",
    "thereAreErrors": "Existem erros de formulário",
    "newQueue": "Nova fila",
    "editQueue": "Editar fila",
    "save": "Guardar",
    "close": "Fechar",
    "edit": "Editar",
    "cancel": "Cancelar",
    "delete": "Apagar",
    "title": {
      "label": "Título",
      "placeholder": "Título",
      "required": "Insira um título",
      "invalid": "Insira um título válido"
    },
    "prefix": {
      "label": "Prefixo",
      "placeholder": "Prefixo",
      "alphanumeric": "Insira apenas letras e números",
      "required": "Insira um prefixo",
      "invalid": "Insira um prefixo válido"
    }
  },
  "en": {
    "charsLimit": "Limit of {limit} characters.",
    "thereAreErrors": "There are errors in the form",
    "newQueue": "New queue",
    "editQueue": "Edit queue",
    "save": "Save",
    "close": "Close",
    "edit": "Edit",
    "cancel": "Cancel",
    "delete": "Delete",
    "title": {
      "label": "Title",
      "placeholder": "Title",
      "required": "Enter a title",
      "invalid": "Enter a valid title"
    },
    "prefix": {
      "label": "Prefix",
      "placeholder": "Prefix",
      "alphanumeric": "Enter letters and numbers only",
      "required": "Enter a prefix",
      "invalid": "Enter a valid prefix"
    }
  }
}
</i18n>
